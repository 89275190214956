import styled from 'styled-components'
import { Col } from 'reactstrap';

export const StyledCol = styled(Col).attrs({
})`
background: ${props => props.green && props.theme.palette.colors.green};
${props => props.centred ? `
    display: flex;
    align-items: center;
    justify-content: center;
    ` : ``
    }
`;


export const SideBackgroundImageContainer = styled.div.attrs({
})`          
    background: ${props => props.theme.palette.colors.blue};
    position: fixed;

    @media(max-width: 767px){ 
        position: relative;
        min-height:initial; 
        width: 100%;
    }
`;
export const SideBackgroundImage = styled.div.attrs({
})`          
    background:  url(${props => props.imgUrlBackground}) no-repeat left center;
    background-size: ${props => props.imgSize};
    height: 100vh;
    @media(max-width: 767px){ 
        position: absolute;
        height: -webkit-fill-available;
        min-height: 120px; 
        height: 100%;
        width: 100%;
        z-index: 1;
    }
`;
export const SideBackgroundImageDegree = styled.div.attrs({
})`          
    min-height:100vh; 
    position: absolute;
    top: 0;
    left: 0;
    right: 0;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content:  flex-end;

    @media(max-width: 767px){  
        position: relative;
        min-height:initial; 
        padding-top: 20px;
        z-index: 2;
        min-height: 120px;  
    }
`;
export const FormContent = styled.div.attrs({
})`
    display: flex;
    justify-content: ${props => props.start ? 'flex-start' : 'center'};
    align-items: center;
    height: 100vh;
    flex-direction: column;
    z-index: 2;
    background: ${props => props.theme.palette.primary.lightGrey};
       ${props => props.blue && `
        background: ${props.theme.palette.colors.blue} url(/icons/logobg.svg) no-repeat -240px center / cover; 
    `}
`;
export const AppLogo = styled.img.attrs({
    src: '/logo1024.png',
    width: 120
})`           
`;

export const Content = styled.div.attrs({
})`           
    overflow:hidden;
    background: ${props => props.theme.palette.primary.lightGray};
 
`;

export const Touch = styled.div.attrs({
})`
cursor: pointer;
`;

export const LogoImage = styled.img.attrs({
    src: '/images/logoWhite.png',
    alt: 'Logo da empresa',
})`
  max-width: 256px;
  `;