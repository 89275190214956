import React, { useContext, useRef, useState } from "react";
import ContainerAuthenticated from "containers/Authenticated";
import Header from "components/Header";
import { Content } from "ui/styled";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { toast } from "react-toastify";
import { CoreContext } from "context/CoreContext";
import { Create, Update } from "services/core";
import { tipoProdutoOptions } from "utils/options";
import ProductsForms from "components/FormsConfig/ProductsForms";


export default function MachineMaintenanceFabril() {
  const history = useHistory();
  const navigate = (to) => history.push(`/${to}`);
  const [saving, setSaving] = useState(false);
  const { currentCompany } = useContext(CoreContext);


  const refForm = useRef();

  const cancel = () => {
    history.goBack();

  }

  const success = () => {
    toast.success('Evento salvo com sucesso');
    history.goBack();
  }


  const formItems = [
    {
      ref: 'machine',
      options: [],
      placeholder: 'Máquinas',
      onBlur: (value) => { console.log('Código blurred:', value); },
    },
    {
      ref: 'reason',
      placeholder: 'Motivos de parada',
      onBlur: (value) => { console.log('Descrição blurred:', value); },
      options: []
    },
    {
      ref: 'observation',
      type: 'text',
      placeholder: 'Observação',
      onBlur: (value) => { console.log('Tipo de produto selecionado:', value); },
    },
    {
      ref: 'start',
      type: 'date',
      placeholder: 'Data de início',
      onBlur: (value) => { console.log('Unidade blurred:', value); },
    },
    {
      ref: 'end',
      placeholder: 'Data de fim',
      type: 'date',
      onBlur: (value) => { console.log('Lead time compra (dias) blurred:', value); },
    },
    {
      ref: 'id',
      type: 'checkbox',
      placeholder: 'Aplicado no cenário',
      onChange: (checked) => { console.log('Consumo por volume:', checked); },
    },
  ];
  const save = async () => {
    const form = refForm.current.getForm();
    const payload = {
      ...form,
    };

    delete payload.id;

    if (!valid(payload, formItems)) {
      toast.error("Preencha todos os campos");
      return;
    }

    setSaving(true);
    const result = currentCompany?.id ? await Update({ data: payload }, currentCompany?.id) : await Create({ data: payload });

    if (result && !result.error) {
      success();
    } else {
      toast.error("Erro ao salvar, tente novamente mais tarde");
    }
    // success();
    setSaving(false);
  };

  const valid = (payload, array) => {
    for (let item of array) {
      if (item?.ref && !payload?.[item?.ref]) {
        return false;
      }
    }
    return true;
  };
  return (
    <ContainerAuthenticated>
      <Header title={'Manutenção de máquinas'} icon cancel={cancel} save={save} />
      <Content>
        <ProductsForms formItems={formItems} saving={saving} refForm={refForm} />
      </Content>
    </ContainerAuthenticated>
  );
}