import React from "react";

import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import NotFound from 'screens/NotFound'

import Login from 'screens/Authentication/Login'
import Register from 'screens/Authentication/Register'
import Forgot from 'screens/Authentication/Forgot'
import CreatePassword from 'screens/Authentication/CreatePassword'

import DashboardHome from 'screens/Dashboard/Home'
import DashboardMe from 'screens/Dashboard/Me'
import Characteristics from "screens/Dashboard/Characteristics";
import Events from "screens/Dashboard/Event";
import Processes from "screens/Dashboard/Processes";
import MachineGroups from "screens/Dashboard/MachineGroups";
import Machine from "screens/Dashboard/Machine";
import Products from "screens/Dashboard/Products";
import ProductsStructures from "screens/Dashboard/ProductsStructures";
import ReasonsStopping from "screens/Dashboard/ReasonsStopping";
import MachineMaintenance from "screens/Dashboard/MachineMaintenance";
import ProductionOrder from "screens/Dashboard/ProductionOrder";
import GanttChart from "screens/Dashboard/GanttChart";
import EventLaunch from "screens/Dashboard/EventLaunch";
import EventsForm from "screens/Dashboard/FormsPage/EventsForm";
import CharacteristicsForm from "screens/Dashboard/FormsPage/CharacteristicsForm";
import ProcessesForm from "screens/Dashboard/FormsPage/ProcessesForm";
import MachineGroupsForm from "screens/Dashboard/FormsPage/MachineGroupsForm";
import MachineForm from "screens/Dashboard/FormsPage/MachineForm";
import ProductForm from "screens/Dashboard/FormsPage/ProductForm";
import ProductsStructuresForm from "screens/Dashboard/FormsPage/ProductsStructuresForm";
import ReasonsStoppingForm from "screens/Dashboard/FormsPage/ReasonsStoppingForm";
import MachineMaintenanceForm from "screens/Dashboard/FormsPage/MachineMaintenanceForm";
import ProductionOrderForm from "screens/Dashboard/FormsPage/ProductionOrderForm";
import EventsLaunchForm from "screens/Dashboard/FormsPage/EventsLaunchForm";
import ScenarioParameters from "screens/Dashboard/ScenarioParameters";
import MachineMaintenanceFabril from "screens/Dashboard/FormsPage/MachineMaintenanceFabril";

export default function AppRouter() {
  return (
    <Router>
      <div>
        <Switch>

          <Route path="/" exact> <Login /> </Route>
          <Route path="/login" exact> <Login /> </Route>
          <Route path="/register" exact> <Register /> </Route>

          <Route path="/forgot" exact> <Forgot /> </Route>
          <Route path="/create-password" exact> <CreatePassword /> </Route>

          <Route path="/dashboard" exact> <DashboardHome /> </Route>
          <Route path="/dashboard/Me" exact> <DashboardMe /> </Route>

          <Route path="/characteristics" exact> <Characteristics /> </Route>
          <Route path="/characteristics/form" exact> <CharacteristicsForm /> </Route>

          <Route path="/events" exact> <Events /> </Route>
          <Route path="/events/form" exact> <EventsForm /> </Route>

          <Route path="/processes" exact> <Processes /> </Route>
          <Route path="/processes/form" exact> <ProcessesForm /> </Route>

          <Route path="/machine-groups" exact> <MachineGroups /> </Route>
          <Route path="/machine-groups/form" exact> <MachineGroupsForm /> </Route>

          <Route path="/machine" exact> <Machine /> </Route>
          <Route path="/machine/form" exact> <MachineForm /> </Route>

          <Route path="/products" exact> <Products /> </Route>
          <Route path="/products/form" exact> <ProductForm /> </Route>

          <Route path="/products-structures" exact> <ProductsStructures /> </Route>
          <Route path="/products-structures/form" exact> <ProductsStructuresForm /> </Route>

          <Route path="/reasons-stopping" exact> <ReasonsStopping /> </Route>
          <Route path="/reasons-stopping/form" exact> <ReasonsStoppingForm /> </Route>

          <Route path="/machine-maintenance" exact> <MachineMaintenance /> </Route>
          <Route path="/machine-maintenance/form" exact> <MachineMaintenanceForm /> </Route>
          <Route path="/machine-maintenance/fabril" exact> <MachineMaintenanceFabril /> </Route>

          <Route path="/production-order" exact> <ProductionOrder /> </Route>
          <Route path="/production-order/form" exact> <ProductionOrderForm /> </Route>

          <Route path="/gantt-chart" exact> <GanttChart /> </Route>

          <Route path="/events-launch" exact> <EventLaunch /> </Route>
          <Route path="/events-launch/form" exact> <EventsLaunchForm /> </Route>

          <Route path="/scenario-parameter" exact> <ScenarioParameters /> </Route>


          <Route path="*" exact> <NotFound /> </Route>
        </Switch>
      </div>
    </Router>
  );
}