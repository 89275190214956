import styled from "styled-components";

export const CalendarContainer = styled.div.attrs({
})`
    min-width: 748px;
    width: 100%;
    padding:  16px 15px 16px 15px;
    gap: 8px;
    background: ${props => props.theme.palette.white.lightWhite};
    border-radius: 16px;
`;

export const CalendarHeader = styled.div.attrs({
})`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 14px;
`;

export const MonthYear = styled.div.attrs({
})`
    font-family: Poppins;
    font-size: 18px;
    font-weight: 600;
    line-height: 27px;
    text-align: left;
    color: ${props => props.theme.palette.blue.main};

`;

export const Navigation = styled.div.attrs({
})`
    display: flex;
    align-items: center;
    width: 200px;
    justify-content: space-between;

`;

export const NavButton = styled.div.attrs({
})`
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    ${props => props.rotation ? `
        transform: rotate(180deg);
      `: ``};
`;

export const CalendarGrid = styled.div.attrs({
})`
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 4px;
    width: 100%;
`;

export const DayCell = styled.div.attrs({
})`
    background: ${props => props.isSunday ? props.theme.palette.colors.lightshadow : props.theme.palette.white.main};
    border-radius: 8px;
    padding: 6px 0;
    width: 100%;
    min-height: 146px;
    border: 0.5px solid ${props => props.theme.palette.colors.grey};
`;
export const DayCellContent = styled.div.attrs({
})`
    display: flex;
    justify-content: space-between;
    padding: 0 8px 8px;
    border-bottom: 1px solid ${props => props.theme.palette.colors.grey};
`;
export const DayLabel = styled.div.attrs({
})`
    font-family: Poppins;
    font-size: 12px;
    font-weight: 700;
    line-height: 16px;
    text-align: left;

    color: ${props => props.isSunday ? props.theme.palette.colors.lightgrey : props.theme.palette.colors.blue};
`;

export const EditIcon = styled.img.attrs({
    src: '/icons/pencil.svg',
})`
    cursor: pointer;
`;

export const EventList = styled.div`
    margin-top: 4px;
    display: flex;
    flex-direction: column;
    gap: 4px;
    `;

export const EventContent = styled.div.attrs({
})`
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: ${props => props.center ? 'center' : ' space-between'};
`;
export const EventItemTitle = styled.div.attrs({
})`
    font-family: Poppins;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    color: ${props => props.theme.palette.colors.darkgrey};
    background: linear-gradient(
        90deg, 
        ${props => props.start
        ? `${props.theme.palette.gradient.main}, ${props.theme.palette.gradient.secondary}`
        : `${props.theme.palette.gradient.tertiary}, ${props.theme.palette.gradient.quaternary}`
    } 100%
    );
    cursor: pointer;
    ${props => props.start ? `
        border-radius: 0 8px 8px 0;
          padding: 2px 8px 2px 2px;
        ` : `
        border-radius: 8px 0 0 8px;
          padding: 2px 2px 2px 8px;
        `};
`;

export const EventFake = styled.div.attrs({
})`
    width: 100%;
`;

export const CalendarTitle = styled.div.attrs({
})`
    font-family: Poppins;
    font-size: 18px;
    font-weight: 600;
    line-height: 27px;
    text-align: left;
    color: ${props => props.theme.palette.colors.fullgrey};
`;

export const CalendarText = styled.div.attrs({
})`
    font-family: Poppins;
    font-size: 12px;
    font-weight: 300;
    line-height: 20px;
    letter-spacing: 0.5px;
    text-align: left;
    margin-bottom: 24px;
    color: ${props => props.theme.palette.colors.black};
`;