import React from 'react'
import { DeleteButton, EndIcon, OptionsDay, OptionsDayContainer, OptionsDayContent, OptionsDayText, StartIcon } from './styled'
import SelectAndInput from 'components/SelectAndInput'
import { Icon } from 'ui/styled';

export default function OptionsDays({ edit, options, stopDay }) {


  return (
    <>
      <OptionsDayContainer>
        <OptionsDay>
          <OptionsDayContent>
            <OptionsDayText>
              <StartIcon />
              Início</OptionsDayText>
            <SelectAndInput
              options={options}
              edit={edit}
              stopDay={stopDay}
            />
          </OptionsDayContent>
          <OptionsDayContent>
            <OptionsDayText>
              <EndIcon />
              Fim</OptionsDayText>
            <SelectAndInput
              options={options}
              edit={edit}
              stopDay={stopDay}
            />
          </OptionsDayContent>
        </OptionsDay >
        <DeleteButton color={'red'}>
          <Icon icon="trash" />
        </DeleteButton>
      </OptionsDayContainer>
    </>
  )
}
