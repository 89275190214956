import React, { useContext, useRef, useState } from "react";
import ContainerAuthenticated from "containers/Authenticated";
import Header from "components/Header";
import { Content } from "ui/styled";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { toast } from "react-toastify";
import { CoreContext } from "context/CoreContext";
import { Create, Update } from "services/core";
import { dataOptions, eventoOptions, opMaquinaOptions, operadorOptions, processoOptions, produtoOptions, tipoProdutoOptions } from "utils/options";

import ReasonsStoppingForms from "components/FormsConfig/ReasonsStoppingForms";
import EventsLaunchForms from "components/FormsConfig/EventsLaunchForms";


export default function EventsLaunchForm() {
  const history = useHistory();
  const navigate = (to) => history.push(`/${to}`);
  const [saving, setSaving] = useState(false);
  const { currentCompany } = useContext(CoreContext);


  const refForm = useRef();

  const cancel = () => {
    history.goBack();

  }

  const success = () => {
    toast.success('Evento salvo com sucesso');
    history.goBack();
  }


  const formItems = [
    {
      ref: 'evento',
      options: eventoOptions,
      placeholder: 'Evento',
      onBlur: (value) => { console.log('Evento selecionado:', value); },
      oneThird: true
    },
    {
      ref: 'opMaquina',
      options: opMaquinaOptions,
      placeholder: 'Op/Máquina',
      onBlur: (value) => { console.log('Op/Máquina selecionado:', value); },
      twoThirds: true
    },
    {
      ref: 'data',
      options: dataOptions,
      placeholder: 'Data',
      onBlur: (value) => { console.log('Data selecionada:', value); },
    },
    {
      ref: 'operador',
      options: operadorOptions,
      placeholder: 'Operador',
      onBlur: (value) => { console.log('Operador selecionado:', value); },

    },
    {
      ref: 'turno',
      type: 'text',
      placeholder: 'Turno',
      onBlur: (value) => { console.log('Turno blurred:', value); },

    },
    {
      ref: 'observacao',
      type: 'text',
      placeholder: 'Observação',
      onBlur: (value) => { console.log('Observação blurred:', value); },
      full: true
    }
  ];
  const save = async () => {
    const form = refForm.current.getForm();
    const payload = {
      ...form,
    };

    delete payload.id;

    if (!valid(payload, formItems)) {
      toast.error("Preencha todos os campos");
      return;
    }

    setSaving(true);
    const result = currentCompany?.id ? await Update({ data: payload }, currentCompany?.id) : await Create({ data: payload });

    if (result && !result.error) {
      success();
    } else {
      toast.error("Erro ao salvar, tente novamente mais tarde");
    }
    // success();
    setSaving(false);
  };

  const valid = (payload, array) => {
    for (let item of array) {
      if (item?.ref && !payload?.[item?.ref]) {
        return false;
      }
    }
    return true;
  };

  return (
    <ContainerAuthenticated>
      <Header title={'Novo lançamento de eventos'} icon cancel={cancel} save={save} />
      <Content>
        <EventsLaunchForms formItems={formItems} saving={saving} refForm={refForm} />
      </Content>
    </ContainerAuthenticated>
  );
}