import React, { useState } from "react";
import { companyOptions, newScenarioNameOptions, scenarioOptions } from "utils/options";
import { ButtonContainer, Container, Content, CopyContainer, FormSeparator, FormsContainer, Title } from "./styled";
import Button from "components/Form/Button";
import { Icon } from "ui/styled";

export default function ScenarioParametersButton({ title, buttons }) {
  const [isActive, setIsActive] = useState(true);

  return (
    <>
      <FormsContainer >
        <Container onClick={() => setIsActive(!isActive)}>
          <Title>{title}</Title>
          <Icon icon={isActive ? 'chevron-top' : 'chevron-down'} width={23} />
        </Container>
        {
          isActive &&
          <Content>

            <FormSeparator />
            <ButtonContainer>
              {
                buttons.map((button, index) => {
                  return (
                    <Button margin={'0 10px 10px 0'} key={index} width={'223px'} color={button.color} small onClick={button.onClick}>{button.label}</Button>
                  );
                })
              }
            </ButtonContainer>
          </Content>
        }
      </FormsContainer>
    </>
  );
}