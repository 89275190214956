
import { styled } from '@mui/material/styles';

import Checkbox from '@mui/material/Checkbox';

export const MaterialCheckbox = styled(Checkbox)(({ theme, small }) => ({
  '& .MuiSvgIcon-root': {
    width: small ? '14px' : '24px',
    height: small ? '14px' : '24px',
    borderRadius: '4px',
    background: theme.palette.primary.lightGrey,
    color: theme.palette.primary.lightGrey,

  },
  '&.Mui-checked': {
    color: theme.palette.colors.green,

    '& .MuiSvgIcon-root': {
      color: theme.palette.colors.green,


    },
  },

}));
