import styled from 'styled-components'


export const FormContent = styled.div.attrs({
})`
    background: white;
    padding:  40px 25px 30px 25px;
    z-index: 2;
`;

export const FormTitle = styled.div.attrs({
})`  
    color: ${props => props.theme.palette.colors.blue}; 
    font-weight: bold;
    font-size: 24px; 
    font-family: 'Poppins';
    weight: 700;
`;
export const LogoImage = styled.img.attrs({
    src: '/images/logo.png',
    alt: 'Logo da empresa',
})`
  max-width: 256px; 
  margin-bottom: 20px; 
      z-index: 2;
`;
export const FormText = styled.div.attrs({
})`           
    font-size: 15px; 
    color: ${props => props.theme.palette.primary.grey};

    max-width: 300px;
    margin-bottom: 20px;
`;

export const FormSpacer = styled.div.attrs({
})`           
`;

export const RegisterForgot = styled.div.attrs({
})`           
    font-size: 15px; 
    color: ${props => props.theme.palette.colors.grey};
    text-align: center;
    padding: 25px 0;

`;

export const ForgotLink = styled.div.attrs({
})`           
    color: ${props => props.theme.palette.colors.green};
    cursor: pointer;
    &:hover{
        text-decoration: underline;
    }
`;

export const RegisterCall = styled.div.attrs({
})`           
    margin: 30px 0 0px;
    color: ${props => props.theme.palette.colors.grey};
    font-size: 15;
    font-weight: bold;
`;