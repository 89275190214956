import styled from "styled-components";
import { Touch } from "ui/styled";

export const SearchContainer = styled.div.attrs({
})`
  display: flex;
  align-items: center;
  margin-top: 26px;


`;
export const Content = styled.div.attrs({

})`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background-color: ${props => props.theme.palette.white.main};
  padding: 16px;
  gap: 10px;

`;


export const Contain = styled.div.attrs({

})`
  display: flex;
  flex-wrap: wrap; 
  justify-content: space-between;
  flex: 1;

`;
export const SelectContainer = styled.div.attrs({
})`
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap; 
`;