import React, { useState } from "react";

import DashboardSide from "../Side";
import {
    DashboardHeaderContainer,
    DashboardHeaderAction,
    DashboardHeaderActionIcon,
    DashboardMenuContainer
} from "./styled";
import Select from "components/Form/Select";

export default function DashboardHeader({ opened, setOpened, options, officials }) {

    return (
        <>
            <DashboardHeaderContainer>
                <DashboardMenuContainer>
                    <DashboardHeaderAction onClick={() => setOpened(!opened)}>
                        <DashboardHeaderActionIcon src={opened ? '/icons/menu-close.svg' : '/icons/menu.svg'} alt="menu-icon" />
                    </DashboardHeaderAction>
                    <Select placeholder="Cenario" options={options} showPlaceholder padding='10px' onChange={() => {/* onChange action*/ }} />
                </DashboardMenuContainer>
                <Select placeholder="Maria da Silva" width='167px' options={officials} onChange={() => {/* onChange action*/ }} />
            </DashboardHeaderContainer>
            {/* <DashboardSide opened={opened} setOpened={setOpened} /> */}
        </>
    );
}