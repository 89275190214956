import React, { useContext, useRef, useState } from "react";
import ContainerAuthenticated from "containers/Authenticated";
import Header from "components/Header";
import { Content, ContentIcon, ContentTableAction, Icon } from "ui/styled";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { toast } from "react-toastify";
import { CoreContext } from "context/CoreContext";
import { Create, Update } from "services/core";
import { estruturaProdutoOptions, prioridadeOptions, proximaOrdemProducaoOptions, qtdeOptions, statusOptions, tipoAcondicionamentoOptions, tipoOptions } from "utils/options";
import ProductionOrderForms from "components/FormsConfig/ProductionOrderForms";
import TableForm from "components/Form/TableForms";



export default function ProductionOrderForm() {
  const history = useHistory();
  const navigate = (to) => history.push(`/${to}`);
  const [saving, setSaving] = useState(false);
  const { currentCompany } = useContext(CoreContext);


  const refForm = useRef();

  const cancel = () => {
    history.goBack();

  }

  const success = () => {
    toast.success('Evento salvo com sucesso');
    history.goBack();
  }


  const formItems = [
    {
      ref: 'codigo',
      type: 'text',
      placeholder: 'Código',
      onBlur: (value) => { console.log('Código blurred:', value); },
      sixThirth: true
    },
    {
      ref: 'qtde',
      options: qtdeOptions,
      placeholder: 'Qtde.',
      onBlur: (value) => { console.log('Qtde. selecionada:', value); },
      sixThirth: true
    },
    {
      ref: 'estruturaProduto',
      options: estruturaProdutoOptions,
      placeholder: 'Estrutura de produto',
      onBlur: (value) => { console.log('Estrutura de produto selecionada:', value); },
      twoThirds: true
    },
    {
      ref: 'tipoAcondicionamento',
      options: tipoAcondicionamentoOptions,
      placeholder: 'Tipo de acondicionamento',
      onBlur: (value) => { console.log('Tipo de acondicionamento selecionado:', value); },
    },
    {
      ref: 'tipo',
      options: tipoOptions,
      placeholder: 'Tipo',
      onBlur: (value) => { console.log('Tipo selecionado:', value); },
    },
    {
      ref: 'status',
      options: statusOptions,
      placeholder: 'Status',
      onBlur: (value) => { console.log('Status selecionado:', value); },
    },
    {
      ref: 'prioridade',
      options: prioridadeOptions,
      placeholder: 'Prioridade',
      onBlur: (value) => { console.log('Prioridade selecionada:', value); },
      half: true
    },
    {
      ref: 'proximaOrdemProducao',
      options: proximaOrdemProducaoOptions,
      placeholder: 'Próxima ordem de produção',
      onBlur: (value) => { console.log('Próxima ordem de produção selecionada:', value); },
      half: true
    }
  ];
  const save = async () => {
    const form = refForm.current.getForm();
    const payload = {
      ...form,
    };

    delete payload.id;

    if (!valid(payload, formItems)) {
      toast.error("Preencha todos os campos");
      return;
    }

    setSaving(true);
    const result = currentCompany?.id ? await Update({ data: payload }, currentCompany?.id) : await Create({ data: payload });

    if (result && !result.error) {
      success();
    } else {
      toast.error("Erro ao salvar, tente novamente mais tarde");
    }
    // success();
    setSaving(false);
  };

  const valid = (payload, array) => {
    for (let item of array) {
      if (item?.ref && !payload?.[item?.ref]) {
        return false;
      }
    }
    return true;
  };
  const columns = [
    { title: 'PROCESSO', ref: 'processo', align: 'left', fullWidth: true },
    { title: 'QTDE', ref: 'qtde' },
    {
      title: 'Ações',
      renderCell: ({ row }) => (
        <ContentTableAction>
          <ContentIcon onClick={() => { /* edit action */ }}>
            <Icon icon='edit' />
          </ContentIcon>
          <ContentIcon onClick={() => { /* delete action */ }}>
            <Icon icon='excluir' />
          </ContentIcon>
        </ContentTableAction>
      ),
    },
  ];

  const [rows, setRows] = useState([
    { processo: 'Descrição', qtde: 123, id: 1 },
  ]);

  const handleAddRow = (newRow) => {
    setRows([...rows, { ...newRow, id: rows.length + 1 }]);
  };

  const handleSave = () => {
    console.log('Rows saved', rows);
  };
  return (
    <ContainerAuthenticated>
      <Header title={'Nova ordem de produção'} icon cancel={cancel} save={save} />
      <Content>
        <ProductionOrderForms formItems={formItems} saving={saving} refForm={refForm} />
      </Content>
      <Content>
        <TableForm columns={columns} rows={rows} onAddRow={handleAddRow} onSave={handleSave} title={'Processos'} />
      </Content>
    </ContainerAuthenticated>
  );
}