import Button from "components/Form/Button";
import styled from "styled-components";

export const OptionsDay = styled.div.attrs({
})`
    display: flex;
    flex-direction: column;
    gap: 8px;

`;

export const OptionsDayContent = styled.div.attrs({
})`
    display: flex;
    align-items: center;
    gap: 4px;
`;

export const StartIcon = styled.div.attrs({
})`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 8px;
    height: 8px;
    background: ${props => props.theme.palette.colors.red};
`;


export const EndIcon = styled.div.attrs({
})`
  width: 0;
  height: 0;
  border-top: 4.5px solid transparent;
  border-bottom: 4.5px solid transparent;
  border-left: 7.5px solid ${props => props.theme.palette.colors.green};

`;
export const OptionsDayText = styled.div.attrs({
})`
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    text-align: left;
    color: ${props => props.theme.palette.primary.grey};
    display: flex;
    align-items: center;
    gap: 8px;
    width: 54px;
`;

export const DeleteButton = styled.div.attrs({
})`
    min-height: 72px;
    width: 32px;
    background: ${props => props.theme.palette.colors.lightPink};
    justify-content: center;
    display: flex;
    align-items: center;
    border-radius: 10px;
    cursor: pointer;
`;

export const OptionsDayContainer = styled.div.attrs({
})`
    width: fit-content;
    display: flex;
    align-items: center;
    background: ${props => props.theme.palette.white.main};
    border-radius: 16px;
    padding: 16px 8px;
    border: 1px solid ${props => props.theme.palette.primary.lightGrey};
    gap: 8px;

`;
