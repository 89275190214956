import styledCmp from 'styled-components';
import { styled } from '@mui/material/styles';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';

export const MaterialSelect = styled(Select)(({ theme, ...props }) => ({
  fontSize: '14px',
  width: props.width ? props.width : '260px',
  '& .MuiSelect-select': {
    padding: props.padding ? props.padding : '5px 10px',
    borderRadius: props.radius ? props.radius : '0 8px 8px 0',
    backgroundColor: props.lightWhite ? theme.palette.white.lightWhite : '',
    border: `1px solid ${theme.palette.colors.grey}`,
    color: props.secondary ? theme.palette.secondary.main : theme.palette.primary.main,
  }
}));
export const InputContainer = styledCmp.div.attrs({
})`
  display: flex;
  width: ${props => props.full && '100%'} ;
  padding-top: ${props => props.paddingTop ? props.paddingTop : '0'} ;
 ${props => props.marginRight ? ` 
    margin-right: 16px;
  ` : ``
  }
  ${props => props.collumn ? `
    flex-direction: column;
  ` : ``}
`;

export const MaterialLabel = styled(InputLabel)(({ theme, ...props }) => ({
  backgroundColor: theme.palette.colors.grey,
  borderRadius: '8px 0 0 8px',
  border: `1px solid ${theme.palette.colors.grey}`,
  fontSize: '14px',
  width: '81px',
  textAlign: 'center',
  padding: '10px',
  color: theme.palette.primary.main,

}));

export const IconProfile = styledCmp.img.attrs({

})`
  width: 42px;
  border-radius: 8px 0 0 8px;
`;

export const SelectContainer = styledCmp.div.attrs({

})`
  line-height: normal;
`;

export const SelectText = styledCmp.div.attrs({

})`
  font-size: 12px;
  color: ${props => props.theme.palette.colors.lightgrey};
`;

export const SelectItem = styled(MenuItem)(({ theme, ...props }) => ({
}));

export const StyledInputLabel = styled(InputLabel)(({ theme, ...props }) => ({
  fontSize: '14px',
  color: theme.palette.colors.black,

}));