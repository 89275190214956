import React, { useContext, useEffect, useState } from 'react'
import { ButtonClose, ButtonContent, EditText, EditTitle, CloseContainer, Content, EditStopContainer, ModalBody, ModalContainer, ModalContent, Overlay, SetupContainer, WeeklySetupContainer, MachineSelectionTitle, } from './styled'
import { CoreContext } from 'context/CoreContext'
import { ContentIcon, ContentTableAction, Icon, Title } from 'ui/styled'
import Button from 'components/Form/Button'
import FormCore from "../../Form/Core";






export default function DeleteFactoryCalendar() {

    const { setModal } = useContext(CoreContext)
    const [isVisible, setIsVisible] = useState(true);
    const [selected, setSelected] = useState([]);

    const handleClicked = () => {
        setIsVisible(false);
        setModal(null)
    }


    const formItems = [
        {
            ref: 'machine',
            placeholder: 'Máquinas (seleção múltipla / selecionar todos)',
            onBlur: (value) => { console.log('Código blurred:', value); },
            options: [],
        },
        {
            ref: 'start',
            placeholder: 'Data de início',
            onBlur: (value) => { console.log('Código blurred:', value); },
            options: [],
        },
        {
            ref: 'end',
            placeholder: 'Data de fim',
            onBlur: (value) => { console.log('Código blurred:', value); },
            options: [],
        },

    ]
    return (
        <>
            <Overlay onClick={handleClicked} />
            <ModalContainer >
                <ModalBody>
                    <ModalContent isVisible={isVisible}>
                        <EditStopContainer>
                            <MachineSelectionTitle>
                                Excluir calendário fabril
                            </MachineSelectionTitle>
                            <ButtonClose >
                                <Icon icon='close-big' onClick={handleClicked} />
                            </ButtonClose>
                        </EditStopContainer>
                        <FormCore formItems={formItems} />

                        <ButtonContent>
                            <Button color={'cancel'} onClick={handleClicked} width={'131px'}>
                                Cancelar
                            </Button>
                            <Button color={'blue'} width={'105px'}>
                                Salvar
                            </Button>
                        </ButtonContent>
                    </ModalContent>
                </ModalBody>
            </ModalContainer >
        </>
    )
}
