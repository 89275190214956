import React, { useState } from "react";
import ContainerAuthenticated from "containers/Authenticated";
import Header from "components/Header";
import { Content, } from "ui/styled";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import FilterForms from "components/FilterForms";
import { clientOptions, machineGroupOptions, machineOptions, productOptions, purchaseOrderOptions, salesOrderOptions, warehouseOptions } from "utils/options";
import { GanttChartSearch } from "components/GanttChartSearch/Index";

export default function GanttChart() {
  const history = useHistory();
  const navigate = (to) => history.push(`/${to}`);
  const [isActive, setIsActive] = useState(false);


  const formItems = [
    {
      ref: 'gruposMaquinas',
      type: 'select',
      placeholder: 'Grupos de máquinas',
      options: machineGroupOptions,
      onBlur: (value) => { console.log('Grupos de máquinas blurred:', value); },
      full: true,
    },
    {
      ref: 'maquinas',
      type: 'select',
      placeholder: 'Máquinas',
      options: machineOptions,
      onBlur: (value) => { console.log('Máquinas blurred:', value); },
      full: true,
    },
    {
      ref: 'produtos',
      type: 'select',
      placeholder: 'Produtos',
      options: productOptions,
      onBlur: (value) => { console.log('Produtos blurred:', value); },
      full: true,
    },
    {
      ref: 'pedidosVendas',
      type: 'select',
      placeholder: 'Pedidos de vendas',
      options: salesOrderOptions,
      onBlur: (value) => { console.log('Pedidos de vendas blurred:', value); },
      full: true,
    },
    {
      ref: 'clientes',
      type: 'select',
      placeholder: 'Clientes',
      options: clientOptions,
      onBlur: (value) => { console.log('Clientes blurred:', value); },
      full: true,
    },
    {
      ref: 'pedidosCompras',
      type: 'select',
      placeholder: 'Pedidos de compras',
      options: purchaseOrderOptions,
      onBlur: (value) => { console.log('Pedidos de compras blurred:', value); },
      full: true,
    },
    {
      ref: 'galpoes',
      type: 'select',
      placeholder: 'Galpões',
      options: warehouseOptions,
      onBlur: (value) => { console.log('Galpões blurred:', value); },
      full: true,
    }
  ];
  return (
    <ContainerAuthenticated>
      <Header title='Gráfico de gantt (Vis)' onFilterClick={() => setIsActive(!isActive)} />
      <GanttChartSearch />
      <FilterForms formItems={formItems} isActive={isActive} setIsActive={setIsActive} />
    </ContainerAuthenticated>
  );
}