import styled from "styled-components";
import { Touch } from "ui/styled";

export const HeaderContainer = styled.div.attrs({
})`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 24px;

`;


export const HeaderTitle = styled.div.attrs({
})`            
    font-size: 21px;
    font-weight: bold;
    align-items: center;
    color: ${props => props.theme.palette.colors.black};
  }
`;

export const HeaderAction = styled.div.attrs({
})`           
    cursor: pointer;
    padding-right: 8px;
`;

export const HeaderActionIcon = styled.img.attrs({
})`
    width: 28px;
`;

export const Content = styled.div.attrs({

})`
    display: flex;
    align-items: center;

`;

export const FilterButton = styled(Touch).attrs({

})`
  display: flex;
  align-items: center;
  padding: 1px 15px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.palette.colors.lightgreen};
  border: 1px solid ${({ theme }) => theme.palette.colors.green};
`;

export const ButtonContent = styled.div.attrs({

})`
        display: flex;
        gap: 16px;
    `;