import React from 'react';
import { styled } from '@mui/material/styles';
import TablePagination from '@mui/material/TablePagination';
import Button from '@mui/material/Button';
import { Content } from './styled';

const CustomPagination = styled(TablePagination)(({ theme }) => ({
  '& .MuiTablePagination-actions': {
    display: 'flex',
    alignItems: 'center',
  },
  '& .MuiButtonBase-root': {
    margin: theme.spacing(0.5),
    minWidth: 36,
    height: 36,
    backgroundColor: theme.palette.grey[200],
    color: theme.palette.text.primary,
    '&.Mui-selected': {
      backgroundColor: theme.palette.blue.main,
      color: theme.palette.blue.contrastText,
    },
  },
  '& .MuiTablePagination-toolbar': {
    padding: 0,
    '& .MuiTablePagination-spacer': {
    },
    '& .MuiTablePagination-selectLabel, & .MuiTablePagination-displayedRows, & .MuiTablePagination-selectRoot': {
      display: 'none',
    },
  },
}));

export default function CustomTablePagination(props) {
  const { count, page, rowsPerPage, onPageChange } = props;

  return (
    <CustomPagination
      component="div"
      count={count}
      page={page}
      onPageChange={onPageChange}
      rowsPerPage={rowsPerPage}
      rowsPerPageOptions={[rowsPerPage]}
      ActionsComponent={(subProps) => (
        <Content className={subProps.className}>
          <Button
            onClick={(event) => subProps.onPageChange(event, subProps.page - 1)}
            disabled={subProps.page === 0}
          >
            &lt;
          </Button>
          {[...Array(Math.ceil(count / rowsPerPage)).keys()].map((pageNumber) => (
            <Button
              key={pageNumber}
              onClick={(event) => subProps.onPageChange(event, pageNumber)}
              className={subProps.page === pageNumber ? 'Mui-selected' : ''}
            >
              {pageNumber + 1}
            </Button>
          ))}
          <Button
            onClick={(event) => subProps.onPageChange(event, subProps.page + 1)}
            disabled={subProps.page >= Math.ceil(count / rowsPerPage) - 1}
          >
            &gt;
          </Button>
        </Content>
      )}
    />
  );
}

