import styledCmp from 'styled-components'

import { styled } from '@mui/material/styles';
import Input from '@mui/material/Input'; // standard (material)
import { IconButton, InputLabel } from '@mui/material';
// import Input from '@mui/material/FilledInput'; 
// import Input from '@mui/material/OutlinedInput'; 


export const MaterialInput = styled(Input)(({ theme, width, grey }) => ({
  border: `1px solid ${theme.palette.colors.grey}`,
  borderRadius: '8px',
  width: width ? width : '300px',
  padding: '3px 10px',
  backgroundColor: grey && theme.palette.colors.grey,
  fontSize: '14px',
}));

export const InputIcon = styledCmp.img.attrs({
})`
`;
export const StyledIconButton = styled(IconButton)(({ theme, grey }) => ({
  color: grey ? theme.palette.info : theme.palette.colors.green,
}));

export const StyledInputLabel = styled(InputLabel)(({ theme, grey, top }) => ({

  fontSize: '14px',
  color: theme.palette.colors.black,
  marginTop: (top && top) || '0',
}));
