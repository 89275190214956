import React, { useContext, useEffect, useState } from 'react'
import { ButtonClose, ButtonContent, EditText, EditTitle, CloseContainer, Content, EditStopContainer, ModalBody, ModalContainer, ModalContent, Overlay, SetupContainer, WeeklySetupContainer, MachineSelectionTitle, } from './styled'
import { CoreContext } from 'context/CoreContext'
import { ContentIcon, ContentTableAction, Icon, Title } from 'ui/styled'
import Button from 'components/Form/Button'
import OptionsDays from 'components/OptionsDays'
import BasicTable from 'components/Form/Table'





export default function MachineSelection() {

    const { setModal } = useContext(CoreContext)
    const [isVisible, setIsVisible] = useState(true);
    const [selected, setSelected] = useState([]);

    const handleClicked = () => {
        setIsVisible(false);
        setModal(null)
    }


    const columns = [
        { title: 'CÓDIGO', ref: 'code', align: 'right' },
        { title: 'DESCRIÇÃO', ref: 'description' },
        { title: 'Grupo de maq.', ref: 'group' },
        { title: 'GALPÃO', ref: 'shed', },
    ];

    const rows = [
        { code: '123456789', description: 'Descrição', group: 'Descrição', id: 1, shed: 'Descrição', },
        { code: '123456789', description: 'Descrição', group: 'Descrição', id: 1, shed: 'Descrição', },
        { code: '123456789', description: 'Descrição', group: 'Descrição', id: 1, shed: 'Descrição', },
    ];
    return (
        <>
            <Overlay onClick={handleClicked} />
            <ModalContainer >
                <ModalBody>
                    <ModalContent isVisible={isVisible}>
                        <MachineSelectionTitle>
                            Seleção de máquinas
                        </MachineSelectionTitle>
                        <BasicTable columns={columns} rows={rows} selectable={{ selected, setSelected }} />
                        <ButtonContent>
                            <Button color={'cancel'} onClick={handleClicked} width={'216px'}>
                                Cancelar
                            </Button>
                            <Button color={'blue'} width={'216px'}>
                                confirmar
                            </Button>
                        </ButtonContent>
                    </ModalContent>
                </ModalBody>
            </ModalContainer >
        </>
    )
}
