import styled from 'styled-components'

export const DashboardPage = styled.div.attrs({
})`            
    flex: 1;
    background: ${props => props.theme.palette.white.lightWhite};
`;

export const DashboardBody = styled.div.attrs({
})`       
    
    background: ${props => props.theme.palette.white.lightWhite};
    display: flex;
    align-items: flex-start;     
    overflow:auto;
`;

export const DashboardBodyContent = styled.div.attrs({
})`            
    width: 100%;
    border-radius: 11px;
`;

export const Content = styled.div.attrs({
})`           
    display: flex;
    padding: 16px;
    background: ${props => props.theme.palette.white.lightWhite};
    min-height: 100vh;
`; 