import styled from 'styled-components'

export const FormContent = styled.div.attrs({
})`
    background: white;
    padding: 25px 25px;
    width: 90%;
    margin-left: 15px;
    margin: 50px 0;
    ${props => props.sucess && `
        margin: 50px 0;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    `}
`;
export const Content = styled.div.attrs({
})`
    display: flex;
   

`;
export const FormTitle = styled.div.attrs({
})`           
    color: ${props => props.green ? props.theme.palette.primary.green : props.theme.palette.primary.main}; 
    font-weight: bold;
    font-size: 20px; 
`;
export const FormText = styled.div.attrs({
})`           
    font-size: 15px; 
    color: ${props => props.theme.palette.primary.grey};
    margin-bottom: 20px;
`;

export const FormSpacer = styled.div.attrs({
})`           
    margin: 20px 0;
    border: 1px solid ${props => props.theme.palette.colors.grey};
`; 