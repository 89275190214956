import React, { useContext, useRef, useState } from "react";
import ContainerAuthenticated from "containers/Authenticated";
import Header from "components/Header";
import { Content, ContentIcon, ContentTableAction, Icon } from "ui/styled";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { toast } from "react-toastify";
import { CoreContext } from "context/CoreContext";
import { Create, Update } from "services/core";
import { processoOptions, produtoOptions, tipoProdutoOptions } from "utils/options";
import ProductsForms from "components/FormsConfig/ProductsForms";
import TableForm from "components/Form/TableForms";


export default function ProductsStructuresForm() {
  const history = useHistory();
  const navigate = (to) => history.push(`/${to}`);
  const [saving, setSaving] = useState(false);
  const { currentCompany } = useContext(CoreContext);


  const refForm = useRef();
  const cancel = () => {
    history.goBack();
  }

  const success = () => {
    toast.success('Evento salvo com sucesso');
    history.goBack();
  }


  const formItems = [
    {
      ref: 'codigo',
      type: 'text',
      placeholder: 'Código',
      onBlur: (value) => { console.log('Código blurred:', value); },

    },
    {
      ref: 'descricao',
      type: 'text',
      placeholder: 'Descrição',
      onBlur: (value) => { console.log('Descrição blurred:', value); },

    },
    {
      ref: 'produto',
      options: produtoOptions,
      placeholder: 'Produto',
      onBlur: (value) => { console.log('Produto selecionado:', value); },

    }
  ];
  const save = async () => {
    const form = refForm.current.getForm();
    const payload = {
      ...form,
    };

    delete payload.id;

    if (!valid(payload, formItems)) {
      toast.error("Preencha todos os campos");
      return;
    }

    setSaving(true);
    const result = currentCompany?.id ? await Update({ data: payload }, currentCompany?.id) : await Create({ data: payload });

    if (result && !result.error) {
      success();
    } else {
      toast.error("Erro ao salvar, tente novamente mais tarde");
    }
    // success();
    setSaving(false);
  };

  const valid = (payload, array) => {
    for (let item of array) {
      if (item?.ref && !payload?.[item?.ref]) {
        return false;
      }
    }
    return true;
  };
  const columns = [
    { title: 'Matérias-primas', ref: 'materiasprimas', align: 'left', fullWidth: true },
    { title: 'QTDE', ref: 'qtde' },
    {
      title: 'Ações',
      renderCell: ({ row }) => (
        <ContentTableAction>
          <ContentIcon onClick={() => { /* edit action */ }}>
            <Icon icon='edit' />
          </ContentIcon>
          <ContentIcon onClick={() => { /* delete action */ }}>
            <Icon icon='excluir' />
          </ContentIcon>
        </ContentTableAction>
      ),
    },
  ];

  const [rows, setRows] = useState([
    { materiasprimas: '001', qtde: '2323', id: 1 },
  ]);

  const columnsCharacteristic = [
    { title: 'Característica', ref: 'caracter', align: 'left', fullWidth: true },
    { title: 'Sequência', ref: 'sequencia' },
    { title: 'Valor', ref: 'valor' },
    {
      title: 'Ações',
      renderCell: ({ row }) => (
        <ContentTableAction>
          <ContentIcon onClick={() => { /* edit action */ }}>
            <Icon icon='edit' />
          </ContentIcon>
          <ContentIcon onClick={() => { /* delete action */ }}>
            <Icon icon='excluir' />
          </ContentIcon>
        </ContentTableAction>
      ),
    },
  ];

  const [rowsCharacteristic, setRowsCharacteristic] = useState([
    { caracter: '001', sequencia: 'Produto 1', valor: '0,000', id: 1 },
  ]);
  const columnsProduct = [
    { title: 'Característica', ref: 'caracter', align: 'left', fullWidth: true },
    { title: 'Valor', ref: 'valor' },
    {
      title: 'Ações',
      renderCell: ({ row }) => (
        <ContentTableAction>
          <ContentIcon onClick={() => { /* edit action */ }}>
            <Icon icon='edit' />
          </ContentIcon>
          <ContentIcon onClick={() => { /* delete action */ }}>
            <Icon icon='excluir' />
          </ContentIcon>
        </ContentTableAction>
      ),
    },
  ];

  const [rowProduct, setRowscolumnsProduct] = useState([
    { caracter: '001', valor: 'Produto 1', id: 1 },
  ]);

  const handleAddRow = (newRow) => {
    setRows([...rows, { ...newRow, id: rows.length + 1 }]);
  };

  const handleAddRowCharacteristic = (newRow) => {
    setRowsCharacteristic([...rowsCharacteristic, { ...newRow, id: rowsCharacteristic.length + 1 }]);
  };

  const handleAddRowProduct = (newRow) => {
    setRowscolumnsProduct([...rowProduct, { ...newRow, id: rowProduct.length + 1 }]);
  }
  const handleSave = () => {
    console.log('Rows saved', rows);
  };

  const handleAddRowMachineGroup = (newRow) => {
    setRowsMachineGroup([...rowMachineGroup, { ...newRow, id: rowMachineGroup.length + 1 }]);

  }
  const columnsMachineGroup = [
    { title: 'GR. DE MAQ.', ref: 'grMaq', align: 'left' },
    { title: 'DURAÇÃO', ref: 'duracao' },
    { title: 'TEMPO SETUP', ref: 'tempoSetup' },
    { title: 'EFICIÊNCIA', ref: 'eficiencia' },
    { title: 'TEMPO DISP.', ref: 'tempoDisp' },
    { title: 'QTDE. MIN.', ref: 'qtdeMin' },
    { title: 'QTDE. MAX.', ref: 'qtdeMax' },
    {
      title: 'AÇÕES',
      renderCell: ({ row }) => (
        <ContentTableAction>
          <ContentIcon onClick={() => { /* edit action */ }}>
            <Icon icon='edit' />
          </ContentIcon>
          <ContentIcon onClick={() => { /* delete action */ }}>
            <Icon icon='excluir' />
          </ContentIcon>
        </ContentTableAction>
      ),
    },
  ];

  const [rowMachineGroup, setRowsMachineGroup] = useState([
    { grMaq: '123456789', duracao: '0 D, 0h, 0min, 0seg', tempoSetup: '00:00:00', eficiencia: '100', tempoDisp: '00:00', qtdeMin: '0', qtdeMax: '0', id: 1 },
    { grMaq: '123455', duracao: '0 D, 0h, 0min, 0seg', tempoSetup: '00:00:00', eficiencia: '100', tempoDisp: '00:00', qtdeMin: '0', qtdeMax: '0', id: 2 },
  ]);
  const processosFormItems = [
    {
      ref: 'processo',
      options: processoOptions,
      placeholder: 'Processo',
      onBlur: (value) => { console.log('Processo selecionado:', value); },
      twenty: true
    },
    {
      ref: 'sequencia',
      type: 'text',
      placeholder: 'Sequência',
      onBlur: (value) => { console.log('Sequência blurred:', value); },
      twenty: true
    },
    {
      ref: 'qtdeLoteProd',
      type: 'text',
      placeholder: 'Qtde. lote prod.',
      onBlur: (value) => { console.log('Qtde. lote prod. blurred:', value); },
      twenty: true
    },
    {
      ref: 'quebraPorProcesso',
      type: 'text',
      placeholder: '% Quebra p/ processo',
      onBlur: (value) => { console.log('% Quebra p/ processo blurred:', value); },
      twenty: true
    },
    {
      ref: 'salvar',
      type: 'button',
      label: 'Salvar',
      onClick: () => { console.log('Salvar clicado'); },
      buttonStyle: {
        backgroundColor: 'green',
        color: 'white',
      },
      twenty: true
    }
  ];
  return (
    <ContainerAuthenticated>
      <Header title={'Nova estrutura de produto'} icon cancel={cancel} save={save} />
      <Content>
        <ProductsForms formItems={formItems} saving={saving} refForm={refForm} />
      </Content>
      <Content>
        <ProductsForms formItems={processosFormItems} saving={saving} refForm={refForm} />
      </Content>
      <Content>
        <TableForm columns={columnsMachineGroup} rows={rowMachineGroup} onAddRow={handleAddRowMachineGroup} onSave={handleSave} title={'Grupos de máquinas'} />
      </Content>
      <Content>
        <TableForm columns={columns} rows={rows} onAddRow={handleAddRow} onSave={handleSave} title={'Matérias-primas'} />
      </Content>
      <Content>
        <TableForm columns={columnsCharacteristic} rows={rowsCharacteristic} onAddRow={handleAddRowCharacteristic} onSave={handleSave} title={'Características de máquina'} />
      </Content>
      <Content>
        <TableForm columns={columnsProduct} rows={rowProduct} onAddRow={handleAddRowProduct} onSave={handleSave} title={'Características de produto'} />
      </Content>
    </ContainerAuthenticated>
  );
}