import styled from "styled-components";

export const Title = styled.div.attrs({
})`            
    font-size: 16px;
    font-weight: bold;
    color: ${props => props.theme.palette.colors.black};
    ${props => props.centred ? `
            text-align: center;
        ` : ``
  }
`;

export const CopyContainer = styled.div.attrs({
})`
    display: flex;
    width: 100%;
    flex-direction: column;
    background: ${props => props.theme.palette.white.main};
    `;

export const FormsContainer = styled.div.attrs({
})`
    background: ${props => props.theme.palette.white.main};
    padding: 16px;
    border-radius: 16px;
    margin-top: 16px;
`;
export const Container = styled.div.attrs({
})` display: flex;
    align-items: center;
    justify-content: space-between;

    `;

export const FormSeparator = styled.div.attrs({
})` 
    margin: 12px 0;
    border-bottom: 1px solid ${props => props.theme.palette.colors.grey};

    `;