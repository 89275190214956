import React, { useState } from "react";
import ContainerAuthenticated from "containers/Authenticated";
import Header from "components/Header";
import BasicTable from "components/Form/Table";
import { ContentTableAction, Icon, Content, ContentIcon } from "ui/styled";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import SearchComponent from "components/Search";
import FilterForms from "components/FilterForms";
import { eventOptions, machineOptions, operatorOptions, simulatedEventOptions } from "utils/options";

export default function EventLaunch() {
  const history = useHistory();
  const navigate = (to) => history.push(`/${to}`);
  const [selected, setSelected] = useState([]);
  const [isActive, setIsActive] = useState(false);

  const columns = [
    { title: 'OP', ref: 'op', align: 'right' },
    { title: 'CÓDIGO', ref: 'code', align: 'right' },
    { title: 'MÁQUINA', ref: 'machine' },
    { title: 'EVENTO', ref: 'event' },
    { title: 'DATA', ref: 'date' },
    { title: 'QTDE.', ref: 'quantity' },
    { title: 'OPERADOR', ref: 'operator' },
    { title: 'TURNO', ref: 'shift' },
    {
      title: 'AÇÕES',
      renderCell: ({ row }) => (
        <ContentTableAction>
          <ContentIcon onClick={() => { /* edit action */ }}>
            <Icon icon='edit' />
          </ContentIcon>
          <ContentIcon onClick={() => { /* delete action */ }}>
            <Icon icon='excluir' />
          </ContentIcon>
        </ContentTableAction>
      ),
    },
  ];

  const rows = [
    { op: '123456789', code: '123456789', machine: 'Descrição', event: 'Descrição', date: '01/02/24', quantity: '123456789', operator: 'Descrição', shift: 'Descrição', id: 1 },
    { op: '123456789', code: '123456789', machine: 'Descrição', event: 'Descrição', date: '01/02/24', quantity: '123456789', operator: 'Descrição', shift: 'Descrição', id: 2 },
    { op: '123456789', code: '123456789', machine: 'Descrição', event: 'Descrição', date: '01/02/24', quantity: '123456789', operator: 'Descrição', shift: 'Descrição', id: 3 },
    { op: '123456789', code: '123456789', machine: 'Descrição', event: 'Descrição', date: '01/02/24', quantity: '123456789', operator: 'Descrição', shift: 'Descrição', id: 4 },
    { op: '123456789', code: '123456789', machine: 'Descrição', event: 'Descrição', date: '01/02/24', quantity: '123456789', operator: 'Descrição', shift: 'Descrição', id: 5 },
    { op: '123456789', code: '123456789', machine: 'Descrição', event: 'Descrição', date: '01/02/24', quantity: '123456789', operator: 'Descrição', shift: 'Descrição', id: 6 },
    { op: '123456789', code: '123456789', machine: 'Descrição', event: 'Descrição', date: '01/02/24', quantity: '123456789', operator: 'Descrição', shift: 'Descrição', id: 7 },
    { op: '123456789', code: '123456789', machine: 'Descrição', event: 'Descrição', date: '01/02/24', quantity: '123456789', operator: 'Descrição', shift: 'Descrição', id: 8 },
    { op: '123456789', code: '123456789', machine: 'Descrição', event: 'Descrição', date: '01/02/24', quantity: '123456789', operator: 'Descrição', shift: 'Descrição', id: 9 },
    { op: '123456789', code: '123456789', machine: 'Descrição', event: 'Descrição', date: '01/02/24', quantity: '123456789', operator: 'Descrição', shift: 'Descrição', id: 10 },
    { op: '123456789', code: '123456789', machine: 'Descrição', event: 'Descrição', date: '01/02/24', quantity: '123456789', operator: 'Descrição', shift: 'Descrição', id: 11 },
  ];

  const handleNavigate = () => {
    navigate('events-launch/form');
  }



  const formItems = [
    {
      ref: 'maquina',
      type: 'select',
      placeholder: 'Máquina',
      options: machineOptions,
      onBlur: (value) => { console.log('Máquina blurred:', value); },
      full: true,
    },
    {
      ref: 'evento',
      type: 'select',
      placeholder: 'Evento',
      options: eventOptions,
      onBlur: (value) => { console.log('Evento blurred:', value); },
      full: true,
    },
    {
      ref: 'dataInicial',
      type: 'date',
      placeholder: 'Data inicial',
      onBlur: (value) => { console.log('Data inicial blurred:', value); },
      full: true,
    },
    {
      ref: 'dataFinal',
      type: 'date',
      placeholder: 'Data final',
      onBlur: (value) => { console.log('Data final blurred:', value); },
      full: true,
    },
    {
      ref: 'ordemProducao',
      type: 'text',
      placeholder: 'Ordem de produção',
      onBlur: (value) => { console.log('Ordem de produção blurred:', value); },
      full: true,
    },
    {
      ref: 'observacao',
      type: 'text',
      placeholder: 'Observação',
      onBlur: (value) => { console.log('Observação blurred:', value); },
      full: true,
    },
    {
      ref: 'operador',
      type: 'select',
      placeholder: 'Operador',
      options: operatorOptions,
      onBlur: (value) => { console.log('Operador blurred:', value); },
      full: true,
    },
    {
      ref: 'turno',
      type: 'text',
      placeholder: 'Turno',
      onBlur: (value) => { console.log('Turno blurred:', value); },
      full: true,
    },
    {
      ref: 'eventosSimulados',
      type: 'select',
      placeholder: 'Eventos simulados',
      options: simulatedEventOptions,
      onBlur: (value) => { console.log('Eventos simulados blurred:', value); },
      full: true,
    }
  ];
  return (
    <ContainerAuthenticated>
      <Header title='Lançamento de eventos' handleNavigate={handleNavigate} />
      <Content>
        <SearchComponent totalResults={rows.length} onFilterClick={() => { setIsActive(!isActive) }} />
        <BasicTable columns={columns} rows={rows} selectable={{ selected, setSelected }} />
      </Content>
      <FilterForms formItems={formItems} isActive={isActive} setIsActive={setIsActive} />

    </ContainerAuthenticated>
  );
}