import styled from "styled-components";

export const WhiteBg = styled.div.attrs({
})`
    display: flex;

    width: 20px;
    height: 20px;
    overflow: hidden;
    border-radius: 3px;
`;

export const CheckContainer = styled.div.attrs({
})`
    margin-top: -11px;    
`;


export const ResponsibleTable = styled.div.attrs({
})`

    overflow-x: auto;
    // display: grid;
    width: 100%;
`;


export const ResponsibleTableContent = styled.div.attrs({
})`
    min-width: fit-content;
`;

export const MobileContent = styled.div.attrs({
})`
    display: none;
    @media(max-width: 991px){
        display: block;
    }
`;

