import React, { useMemo, useState } from "react";
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';


import {
    FormTitle,
    FormText,
    FormSpacer
} from './styled'

import Button from "components/Form/Button";
import Input from 'components/Form/Input'

import ContainerUnauthenticated from "containers/Unauthenticated";
import { DoResetPassword } from "services/authentication";
import { FormContent } from "../Forgot/styled";
import PasswordValidation from "components/Form/PasswordValidation";
import { ButtonContainer } from "components/Form/Button/styled";
import { ContentIcon, Icon } from "ui/styled";

export default function CreatePassword() {
    const history = useHistory();
    const navigate = to => history.push(`/${to}`);

    const params = new URLSearchParams(window.location.search)
    const [loading, setLoading] = useState(false)

    const [form, setForm] = useState({})
    const formValue = ref => { return form?.[ref] ? form?.[ref] : ''; }
    const changeForm = (value, ref) => { setForm({ ...form, [ref]: value }); }
    const [step, setStep] = useState(0)
    const valid = (verbose = false) => {

        if (!formValue('password') || !formValue('password').length) {
            if (verbose) { toast.error('Preencha o campo: Nova senha'); }
            return false;
        }

        if (!formValue('cpassword') || !formValue('cpassword').length) {
            if (verbose) { toast.error('Preencha o campo: Confirmar nova senha'); }
            return false;
        }

        if (formValue('password') !== formValue('cpassword')) {
            if (verbose) { toast.error('Nova senha e confirmação da nova senha não são iguais'); }
            return false;
        }

        return true
    }

    const action = async () => {
        if (!valid(true)) { return; }
        setLoading(true)

        const result = await DoResetPassword({
            code: params.get('code'),
            password: formValue('password'),
            passwordConfirmation: formValue('cpassword')
        })

        setLoading(false)
        // if (result && !exposeStrapiError(result)) {
        next()
        // }
    }


    const steps = useMemo(() => [
        {
            title: 'Senha',
            text: 'Digite uma nova senha para sua conta',
            label: 'Voltar',
            labelNext: 'Concluir cadastro',
        },
        {
            title: 'Nova senha criada com sucesso',
            text: 'Agora você já pode fazer seu login com sua nova senha',
            label: 'Fechar',
        },

    ], []);
    const next = () => {
        if (step === steps.length - 1) {
            //save data
            navigate('Login')
            return;
        }
        setStep(step + 1);
    }
    const back = () => {
        if (step === 0) {
            navigate('Login')
            return;
        }
        setStep(step - 1);
    }
    return (
        <>
            <ContainerUnauthenticated green logo centred start>
                {
                    !(step === 0) ? null : <> <FormContent>
                        <FormTitle green>{steps?.[step]?.title}</FormTitle>
                        <FormText>{steps?.[step]?.text}</FormText>
                        <Input width='100%' id={'password'} placeholder="Nova Senha" type="password" value={formValue('password')} onChange={e => changeForm(e.target.value, 'password')} grey />
                        <Input top='16px' width='100%' id={'cpassword'} placeholder="Confirmar nova Senha" type="password" value={formValue('cpassword')} onChange={e => changeForm(e.target.value, 'cpassword')} grey />
                        <FormSpacer />
                        <PasswordValidation password={formValue('password')} />
                        <FormSpacer border />
                        <Button nospace width='50%' start onClick={() => navigate('login')}>
                            <ContentIcon>
                                <Icon
                                    icon='chevron-left' />
                                {steps?.[step]?.label}
                            </ContentIcon>
                        </Button>
                        <Button nospace width='50%' color={'green'} spaceBetween loading={loading} onClick={action}>
                            <ContentIcon between>
                                {steps?.[step]?.labelNext}
                                <Icon icon='chevron-right' />
                            </ContentIcon></Button>
                    </FormContent>
                    </>
                }
                {
                    !(step === 1) ? null : <>
                        <FormContent sucess>
                            <FormTitle green>{steps?.[step]?.title}</FormTitle>
                            <FormText>{steps?.[step]?.text}</FormText>
                            <FormSpacer border />
                            <ButtonContainer>
                                <Button width='50%' nospace color={'green'} loading={loading} onClick={next} >{steps?.[step]?.label}</Button>
                            </ButtonContainer>
                        </FormContent>
                    </>
                }

            </ContainerUnauthenticated>
        </>
    );
}