import React, { useContext, useRef, useState } from "react";
import ContainerAuthenticated from "containers/Authenticated";
import Header from "components/Header";
import { Content } from "ui/styled";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { toast } from "react-toastify";
import { CoreContext } from "context/CoreContext";
import { Create, Update } from "services/core";
import { grupoMaquinasOptions, unidadeFabrilOptions } from "utils/options";
import MachineGroupForm from "components/FormsConfig/MachineGroupForm";


export default function MachineGroupsForm() {
  const history = useHistory();
  const navigate = (to) => history.push(`/${to}`);
  const [saving, setSaving] = useState(false);
  const { currentCompany } = useContext(CoreContext);


  const refForm = useRef();

  const cancel = () => {
    navigate('machine-groups');
  }

  const success = () => {
    toast.success('Evento salvo com sucesso');
    history.goBack();
  }

  const formItems = [
    {
      ref: 'codigo',
      type: 'text',
      placeholder: 'Código',
      onBlur: (value) => { console.log('Código blurred:', value); },
      oneThird: true
    },
    {
      ref: 'descricao',
      type: 'text',
      placeholder: 'Descrição',
      onBlur: (value) => { console.log('Descrição blurred:', value); },
      twoThirds: true
    },
    {
      ref: 'tipoGrupoMaquinas',
      options: grupoMaquinasOptions,
      placeholder: 'Tipo do grupo de máquinas',
      onBlur: (value) => { console.log('Tipo do grupo de máquinas selecionado:', value); },
      half: true
    },
    {
      ref: 'unidadeFabril',
      options: unidadeFabrilOptions,
      placeholder: 'Unidade fabril',
      onBlur: (value) => { console.log('Unidade fabril selecionada:', value); },
      half: true
    }
  ];
  const save = async () => {
    const form = refForm.current.getForm();
    const payload = {
      ...form,
    };

    delete payload.id;

    if (!valid(payload, formItems)) {
      toast.error("Preencha todos os campos");
      return;
    }

    setSaving(true);
    const result = currentCompany?.id ? await Update({ data: payload }, currentCompany?.id) : await Create({ data: payload });

    if (result && !result.error) {
      success();
    } else {
      toast.error("Erro ao salvar, tente novamente mais tarde");
    }
    // success();
    setSaving(false);
  };

  const valid = (payload, array) => {
    for (let item of array) {
      if (item?.ref && !payload?.[item?.ref]) {
        return false;
      }
    }
    return true;
  };
  return (
    <ContainerAuthenticated>
      <Header title={'Novo grupo de máquinas'} icon cancel={cancel} save={save} />
      <Content>
        <MachineGroupForm formItems={formItems} saving={saving} refForm={refForm} />
      </Content>
    </ContainerAuthenticated>
  );
}