import React, { useEffect, useState } from "react";

import Header from 'components/Dashboard/Header'

import {
    DashboardPage,
    DashboardBody,
    DashboardBodyContent,
    Content
} from "./styled";
import { ReadObject } from "services/storage";
import { useHistory } from "react-router-dom";
import { ThemedComponent } from "ui/theme";
import DashboardSide from "components/Dashboard/Side";
import { Subtitles } from "@mui/icons-material";

export default function ContainerAuthenticated({ children, page }) {

    const history = useHistory();
    const navigate = to => history.push(`/${to}`);
    const [opened, setOpened] = useState(true)

    const init = () => {
        const authentication = ReadObject('authentication')
        if (!authentication?.jwt) {
            completeNext()
        }
    }

    const completeNext = () => {
        navigate('login')
    }

    useEffect(() => {
        init()
        window.scrollTo(0, 0)
    }, [])

    const options = [
        { id: 1, title: 'Cenário 1' },
        { id: 2, title: 'Cenário 2' },
        { id: 3, title: 'Cenário 3' },
        { id: 4, title: 'Cenário 4' },
        { id: 5, title: 'Cenário 5' },
    ]
    const officials = [
        { id: 1, title: 'Maria da Silva', subtitle: 'Alpha Corp', img: '/images/profile.png' },
        { id: 2, title: 'João da Silva', subtitle: 'Beta Corp', img: '/path/to/joao.png' },
        { id: 3, title: 'José da Silva', subtitle: 'Gamma Corp', img: '/path/to/jose.png' },
        { id: 4, title: 'Joaquim da Silva', subtitle: 'Delta Corp', img: '/path/to/joaquim.png' },
        { id: 5, title: 'Joaquina da Silva', subtitle: 'Epsilon Corp', img: '/path/to/joaquina.png' },
    ];

    return (
        <>
            <ThemedComponent>
                <Content>
                    {
                        opened && <DashboardSide page={page} opened={opened} />
                    }
                    <DashboardPage>
                        <Header opened={opened} setOpened={setOpened} options={options} officials={officials} />
                        <DashboardBody>
                            <DashboardBodyContent>
                                {children}
                            </DashboardBodyContent>
                        </DashboardBody>
                    </DashboardPage>
                </Content>
            </ThemedComponent>
        </>
    );
}