import styled from "styled-components";

export const SelectField = styled.select.attrs({
})`
  padding: 0 8px;
  border-radius: 4px 0 0 4px;
  font-size: 16px;
  height: 32px;
  max-width: 100%;
  padding-right: 34px;
  background: ${props => props.theme.palette.colors.almostlightgray};
  border: 1px solid ${props => props.theme.palette.colors.almostlightgray};
  cursor: pointer;
  :focus {
    outline: none;
  }
`;

export const Option = styled.option.attrs({
})`
  font-size: 16px;
  ${props => props.stopDay ? `
    display: none;
    ` : ``};
  
`;

export const SelectAndInputContainer = styled.div.attrs({
})`
  display: flex;
`;

export const StyledInput = styled.input.attrs({
})`

  border: 1px solid ${props => props.theme.palette.primary.lightGrey};
  border-radius: 0 4px 4px 0;
  font-size: 16px;
  padding: 3px;
  width: 100%;
  ${props => props.edit ? `
    text-align: center;
    `: ``};
    &:focus {
    border-color: ${props => props.theme.palette.primary.lightGrey};
    outline: none;
  }
`;
export const InputContainer = styled.div.attrs({
})`
  display: flex;
  flex-direction: column;
  width: ${props => props.edit ? '94px' : '50px'};

`;

