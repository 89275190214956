import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';

import {
    MaterialCheckbox
} from './styled'
import { styled } from '@mui/material/styles';


import FormGroup from '@mui/material/FormGroup';
import FormLabel from '@mui/material/FormLabel';
import FormControlLabel from '@mui/material/FormControlLabel';

import { ThemedComponent } from "ui/theme";

const StyledFormGroup = styled(FormGroup)(({ theme, checkForm }) => ({
    ...(checkForm && {
        background: theme.palette.white.lightWhite,
        display: 'flex',
        justifyContent: 'center',
        borderRadius: '2px',
        wordWrap: 'break-word',
        padding: '0 10px',
        height: '36px',
        marginTop: '35px',
        whiteSpace: 'nowrap'
    })
}));

export const Check = ({ checked, onChange, label, title, secondary, small, checkForm }) => {

    const [active, setActive] = useState(checked)

    const action = () => {
        const nv = !active
        if (onChange && typeof onChange === 'function') { onChange(nv) }
        setActive(nv)
    }

    useEffect(() => {
        setActive(checked)
    }, [checked])

    return (
        <>
            <ThemedComponent>
                <StyledFormGroup checkForm={checkForm}>
                    {title ? <FormLabel component="legend"
                        color={secondary ? 'secondary' : 'primary'} >{title}</FormLabel> : null}
                    <FormControlLabel control={<MaterialCheckbox small={small} checked={checked} onChange={action}
                        color={secondary ? 'secondary' : 'primary'} />} label={label} />
                </StyledFormGroup>
            </ThemedComponent>

        </>
    );
}

Check.propTypes = {
    label: PropTypes.string.isRequired,
    title: PropTypes.string,
    checked: PropTypes.bool,
    secondary: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
};


Check.defaultProps = {
    label: '',
    title: '',
    checked: false,
    secondary: false,
    onChange: undefined,
};

export default Check;