import React, { useContext, useRef, useState } from "react";
import ContainerAuthenticated from "containers/Authenticated";
import Header from "components/Header";
import { Content, ContentIcon, ContentTableAction, Icon } from "ui/styled";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { toast } from "react-toastify";
import { CoreContext } from "context/CoreContext";
import { Create, Update } from "services/core";
import { eppOptions, galpaoOptions, grupoMaquinasOptions, statusMaquinaOptions } from "utils/options";

import MachineForms from "components/FormsConfig/MachineForms";
import BasicTable from "components/Form/Table";
import TableForm from "components/Form/TableForms";
import MachineFormsSecondary from "components/FormsConfig/MachineFormsSecondary";


export default function MachineForm() {
  const history = useHistory();
  const navigate = (to) => history.push(`/${to}`);
  const [saving, setSaving] = useState(false);
  const { currentCompany } = useContext(CoreContext);


  const refForm = useRef();

  const cancel = () => {
    history.goBack();

  }

  const success = () => {
    toast.success('Evento salvo com sucesso');
    history.goBack();
  }


  const formItems = [
    {
      ref: 'codigo',
      type: 'text',
      placeholder: 'Código',
      onBlur: (value) => { console.log('Código blurred:', value); },
      quarter: true
    },
    {
      ref: 'descricao',
      type: 'text',
      placeholder: 'Descrição',
      onBlur: (value) => { console.log('Descrição blurred:', value); },
      quarter: true
    },
    {
      ref: 'descricaoReduzida',
      type: 'text',
      placeholder: 'Descrição reduzida',
      onBlur: (value) => { console.log('Descrição reduzida blurred:', value); },
      quarter: true
    },
    {
      ref: 'grupoMaquina',
      options: grupoMaquinasOptions,
      placeholder: 'Grupo de máquina',
      onBlur: (value) => { console.log('Grupo de máquina selecionado:', value); },
      quarter: true
    },
    {
      ref: 'statusMaquina',
      options: statusMaquinaOptions,
      placeholder: 'Status de máquina',
      onBlur: (value) => { console.log('Status de máquina selecionado:', value); },
      twenty: true
    },
    {
      ref: 'tempoSetup',
      type: 'text',
      placeholder: 'Tempo de setup',
      onBlur: (value) => { console.log('Tempo de setup blurred:', value); },
      twenty: true
    },
    {
      ref: 'custoPorHora',
      type: 'text',
      placeholder: 'Custo por hora',
      onBlur: (value) => { console.log('Custo por hora blurred:', value); },
      twenty: true
    },
    {
      ref: 'ultimaEppProduzida',
      options: eppOptions,
      placeholder: 'Última EPP Produzida',
      onBlur: (value) => { console.log('Última EPP Produzida selecionada:', value); },
      twenty: true
    },
    {
      ref: 'galpao',
      options: galpaoOptions,
      placeholder: 'Galpão',
      onBlur: (value) => { console.log('Galpão selecionado:', value); },
      twenty: true
    }
  ];
  const save = async () => {
    const form = refForm.current.getForm();
    const payload = {
      ...form,
    };

    delete payload.id;

    if (!valid(payload, formItems)) {
      toast.error("Preencha todos os campos");
      return;
    }

    setSaving(true);
    const result = currentCompany?.id ? await Update({ data: payload }, currentCompany?.id) : await Create({ data: payload });

    if (result && !result.error) {
      success();
    } else {
      toast.error("Erro ao salvar, tente novamente mais tarde");
    }
    // success();
    setSaving(false);
  };

  const valid = (payload, array) => {
    for (let item of array) {
      if (item?.ref && !payload?.[item?.ref]) {
        return false;
      }
    }
    return true;
  };

  const columns = [
    { title: 'Característica', ref: 'caracter', align: 'left' },
    { title: 'Valor', ref: 'description' },
    {
      title: 'Ações',
      renderCell: ({ row }) => (
        <ContentTableAction>
          <ContentIcon onClick={() => { /* edit action */ }}>
            <Icon icon='edit' />
          </ContentIcon>
          <ContentIcon onClick={() => { /* delete action */ }}>
            <Icon icon='excluir' />
          </ContentIcon>
        </ContentTableAction>
      ),
    },
  ];

  const [rows, setRows] = useState([
    { caracter: '001', description: 'Produto 1', id: 1 },
  ]);

  const handleAddRow = (newRow) => {
    setRows([...rows, { ...newRow, id: rows.length + 1 }]);
  };

  const handleSave = () => {
    console.log('Rows saved', rows);
  };

  const formItemsMachine = [

    {
      ref: 'grupoMaquinaSecondary',
      options: grupoMaquinasOptions,
      placeholder: 'Grupo de máquina',
      onBlur: (value) => { console.log('Status de máquina selecionado:', value); },
      hasButton: true,
      buttonLabel: 'Salvar',
      onButtonClick: () => { console.log('Botão clicado'); },
      full: true
    },
  ]
  return (
    <ContainerAuthenticated>
      <Header title={'Nova maquina'} icon cancel={cancel} save={save} />
      <Content>
        <MachineForms formItems={formItems} saving={saving} refForm={refForm} />
      </Content>
      <Content>
        <TableForm columns={columns} rows={rows} onAddRow={handleAddRow} onSave={handleSave} title={'Características'} />
      </Content>
      <Content>
        <MachineFormsSecondary formItems={formItemsMachine} saving={saving} refForm={refForm} />
      </Content>
    </ContainerAuthenticated>
  );
}