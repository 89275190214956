import React, { useContext, useRef, useState } from "react";
import ContainerAuthenticated from "containers/Authenticated";
import Header from "components/Header";
import { Icon } from "ui/styled";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { toast } from "react-toastify";
import { CoreContext } from "context/CoreContext";
import { Create, Update } from "services/core";
import { dataFimOptions, dataInicioOptions, eppOptions, galpaoOptions, grupoMaquinasOptions, maquinaOptions, motivoParadaOptions, statusMaquinaOptions } from "utils/options";

import MachineForms from "components/FormsConfig/MachineForms";
import MachineMaintenanceForms from "components/FormsConfig/MachineMaintenanceForms";
import MachineMaintenaceCalendar from "components/MachineMaintenaceCalendar";
import { ButtonContent, CalendarContainer, MachineMaintenaceHeader, MachineMaintenaceHeaderItem, MachineMaintenaceHeaderText, MachineMaintenanceButton, MachineMaintenanceContainer, MachineMaintenanceTitle, StyledInput } from "./styled";
import Button from "components/Form/Button";
import WeeklySetup from "components/WeeklySetup";

export default function MachineMaintenanceForm() {

  const history = useHistory();
  const navigate = (to) => history.push(`/${to}`);
  const [saving, setSaving] = useState(false);
  const { currentCompany, setModal } = useContext(CoreContext);
  const [form, setForm] = useState({});

  const handleChange = (event, ref) => {
    changeForm(event.target.value, ref);
  };

  const changeForm = (value, ref) => {
    setForm({ ...form, [ref]: value });
  };

  const formValue = ref => { return form?.[ref] ? form?.[ref] : ''; };

  const refForm = useRef();

  const cancel = () => {
    history.goBack();
  }

  const success = () => {
    toast.success('Evento salvo com sucesso');
    history.goBack();
  }

  const formItems = [
    {
      ref: 'maquina',
      options: maquinaOptions,
      placeholder: 'Máquina',
      onBlur: (value) => { console.log('Máquina selecionada:', value); },
    },
    {
      ref: 'motivoParada',
      options: motivoParadaOptions,
      placeholder: 'Motivo de parada',
      onBlur: (value) => { console.log('Motivo de parada selecionado:', value); },
    },
    {
      ref: 'observacao',
      type: 'text',
      placeholder: 'Observação',
      onBlur: (value) => { console.log('Observação blurred:', value); },
    },
    {
      ref: 'dataInicio',
      type: 'date',
      placeholder: 'Data de início',
      onBlur: (value) => { console.log('Data de início selecionada:', value); },
    },
    {
      ref: 'dataFim',
      type: 'date',
      placeholder: 'Data de fim',
      onBlur: (value) => { console.log('Data de fim selecionada:', value); },
    },
    {
      ref: 'aplicadoCenario',
      type: 'checkbox',
      placeholder: 'Aplicado no cenário',
      onChange: (checked) => { console.log('Aplicado no cenário:', checked); },
    }
  ];


  const formHeader = [
    {
      key: 'addMachine',
      title: 'Maquinas',
      type: 'button',
      text: 'Adicionar maquinas',
      icon: 'plus',
      onClick: () => setModal({ type: 'machineselection' }),
    },
    {
      key: 'maquina',
      title: 'MOTIVO DA PARADA',
      type: 'text',
      value: formValue('maquina'),
      onChange: (event) => handleChange(event, 'maquina'),
    },
    {
      key: 'observacao',
      title: 'OBSERVAÇÃO',
      type: 'text',
      value: formValue('observacao'),
      onChange: (event) => handleChange(event, 'observacao'),
      full: true,
    },
  ];
  const save = async () => {
    const form = refForm.current.getForm();
    const payload = {
      ...form,
    };

    delete payload.id;

    if (!valid(payload, formItems)) {
      toast.error("Preencha todos os campos");
      return;
    }

    setSaving(true);
    const result = currentCompany?.id ? await Update({ data: payload }, currentCompany?.id) : await Create({ data: payload });

    if (result && !result.error) {
      success();
    } else {
      toast.error("Erro ao salvar, tente novamente mais tarde");
    }
    setSaving(false);
  };

  const valid = (payload, array) => {
    for (let item of array) {
      if (item?.ref && !payload?.[item?.ref]) {
        return false;
      }
    }
    return true;
  };

  return (
    <ContainerAuthenticated>
      <Header title={'Novo Manutenção de máquinas'} icon cancel={cancel} save={save} />

      <MachineMaintenanceContainer>
        <MachineMaintenanceTitle>
          Informações de parada
        </MachineMaintenanceTitle>
        <MachineMaintenaceHeader>
          {
            formHeader.map(item => (
              <MachineMaintenaceHeaderItem key={item.key} flex={item.full}>
                <MachineMaintenaceHeaderText>
                  {item.title}
                </MachineMaintenaceHeaderText>
                <ButtonContent>
                  {item.type === 'button' ? (
                    <Button small add width={'fit-content'} onClick={item.onClick}>
                      <Icon icon={item.icon} /> {item.text}
                    </Button>
                  ) : (
                    <StyledInput
                      type={item.type}
                      value={item.value}
                      onChange={item.onChange}
                      placeholder={item.placeholder}
                      full={item.full}
                    />
                  )}
                </ButtonContent>
              </MachineMaintenaceHeaderItem>
            ))
          }
        </MachineMaintenaceHeader>
        <CalendarContainer>
          <WeeklySetup />
          <MachineMaintenaceCalendar />
        </CalendarContainer>
      </MachineMaintenanceContainer>
    </ContainerAuthenticated>
  );
}