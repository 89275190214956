import React, { useContext, useState, useEffect } from "react";
import { useHistory, useLocation } from 'react-router-dom';
import {
    DashboardMenuContainer,
    DashboardMenu,
    DashboardMenuOption,
    DashboardMenuContent,
    DashboardLogo,
    DashboardLogoContainer,
    DashBoarMenuOptionWrap,
    DashboardVersionText,
    DashboardMenuOptionIcon
} from "./styled";
import { DoLogout } from "services/authentication";
import { CoreContext } from "context/CoreContext";
import { FormSpacer } from "screens/Authentication/Forgot/styled";
import List from "components/List";
import { sideOptions } from "utils/options";

const refCoparacion = (item) => item.ref !== 'dashboard' && item.ref !== 'scenario-parameter';

export default function DashboardSide({ opened, setOpened, page }) {
    const history = useHistory();
    const location = useLocation();
    const navigate = to => history.push(`/${to}`);
    const { user, currentCompany, activeItems, setActiveItems } = useContext(CoreContext);

    const verifyClose = e => {
        if (!e.target.closest('.menu-contant')) {
            // setOpened(false)
        }
    };

    const handleMenuOptionClick = (itemRef, page) => {
        if (activeItems.includes(itemRef)) {
            setActiveItems(activeItems.filter((ref) => ref !== itemRef));
        } else {
            setActiveItems([...activeItems, itemRef]);
        }
        page && navigate(page);
    };

    const handleMenuSubOptionClick = (itemRef, page) => {

        navigate(page);
    };

    return (
        <>
            {
                !opened ? null :
                    <DashboardMenuContainer onClick={verifyClose}>
                        <DashboardMenu>
                            <DashboardLogoContainer>
                                <DashboardLogo src={`/images/logo-int.png`} alt="logo-icon" />
                            </DashboardLogoContainer>
                            <FormSpacer />
                            <DashboardMenuContent>
                                {
                                    sideOptions.map((item, key) => {
                                        const isActive = location.pathname === `/${item.page}`
                                        return (
                                            <DashboardMenuOption key={key} active={isActive}>
                                                <DashBoarMenuOptionWrap
                                                    onClick={() => handleMenuOptionClick(item.ref, item?.page)}
                                                    lightWhite={activeItems.includes(refCoparacion(item) ? item.ref : '')}
                                                    isDashboardOrScenario={item.ref === 'dashboard' || item.ref === 'scenario-parameter'}
                                                    active={isActive}
                                                >
                                                    {item.title}
                                                    {
                                                        refCoparacion(item) &&
                                                        <DashboardMenuOptionIcon icon={activeItems.includes(item.ref) ? 'open' : 'chevron-right'} />
                                                    }
                                                </DashBoarMenuOptionWrap>
                                                {
                                                    activeItems.includes(item.ref) && item.sideSubOptios &&
                                                    <DashboardMenuContent lightWhite>
                                                        {
                                                            item.sideSubOptios.map((subItem, subKey) => {
                                                                return (
                                                                    <List
                                                                        key={subKey}
                                                                        title={subItem.title}
                                                                        onClick={() => handleMenuSubOptionClick(subItem.ref, subItem.page)}
                                                                        active={location.pathname === `/${subItem.page}`}
                                                                    />
                                                                );
                                                            })
                                                        }
                                                    </DashboardMenuContent>
                                                }
                                            </DashboardMenuOption>
                                        );
                                    })
                                }
                            </DashboardMenuContent>
                        </DashboardMenu>
                    </DashboardMenuContainer>
            }
        </>
    );
}