import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Tooltip from '@mui/material/Tooltip';
import { EmptyMessage, Icon, Load, LoadCenter } from 'ui/styled';
import { CheckContainer, ResponsibleTable, ResponsibleTableContent, WhiteBg } from './styled';
import Check from '../Check';
import { theme } from 'ui/theme-color';
import CustomTablePagination from 'components/CustomTablePagination';

const StyledTableCell = styled(TableCell)(({ theme, width }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.white.main,
    color: theme.palette.info.main,
    textTransform: 'upperCase',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',

  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: width ? width : 'auto',

  },
}));

const StyledTable = styled(Table)({
  tableLayout: 'fixed',
});

export default function BasicTable({ columns, rows, loading, selectable, color }) {
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const keyLength = columns.length - 1;

  const isSelected = item => selected.map(m => m.id).includes(item.id);

  const toggleSelected = item => {
    setSelected(isSelected(item) ? selected.filter(f => f.id !== item.id) : [...selected, item]);
  };

  const toggleAll = () => {
    setSelected(selected.length === rows.length ? [] : rows);
  };

  React.useEffect(() => {
    if (typeof selectable === 'function') {
      selectable(selected);
    }
  }, [selected, selectable]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };


  return (
    <ResponsibleTable>
      <ResponsibleTableContent>
        <TableContainer component={Paper} style={{ overflow: 'auto' }}>
          <StyledTable sx={{ minWidth: 150 }} style={{ borderRadius: 4 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                {selectable && (
                  <StyledTableCell align="right" width={'40px'}>
                    <WhiteBg>
                      <CheckContainer>
                        <Check checked={selected.length === rows.length} onChange={toggleAll} />
                      </CheckContainer>
                    </WhiteBg>
                  </StyledTableCell>
                )}
                {columns.map((item, key) => (
                  <StyledTableCell key={key}
                    align='left'
                    width={key === keyLength ? '110px' : key === 0 ? '120px' : 'auto'}>
                    {item.title}
                    <Icon icon='arrow-top' />
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {(rowsPerPage > 0
                ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                : rows
              ).map((row) => (
                <TableRow key={row.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  {selectable && (
                    <TableCell align="center">
                      <WhiteBg>
                        <CheckContainer>
                          <Check checked={isSelected(row)} onChange={() => toggleSelected(row)} />
                        </CheckContainer>
                      </WhiteBg>
                    </TableCell>
                  )}
                  {columns.map((item, key) => {
                    const canClickAction = row.action && !item['renderCell'];
                    return (
                      <StyledTableCell
                        key={key}
                        style={{
                          background: row.background
                            ? theme.palette[color ? color : 'colors'][row.background]
                            : row.totalizer
                              ? theme.palette.colors.backgroundcard
                              : item.background
                                ? theme.palette[color ? color : 'colors'][item.background]
                                : 'transparent',
                          color: row.color
                            ? theme.palette[color ? color : 'colors'][row.color]
                            : theme.palette.colors.text,
                          cursor: canClickAction ? 'pointer' : 'auto',
                        }}
                        width={item.width ? item.width : 'auto'}
                        onClick={canClickAction ? row.action : null}
                      >
                        <Tooltip title={row[item.ref]} arrow>
                          <span>{item['renderCell'] ? item.renderCell({ row }) : row[item.ref]}</span>
                        </Tooltip>
                      </StyledTableCell>
                    );
                  })}
                </TableRow>
              ))}
            </TableBody>
          </StyledTable>
          {!loading ? (
            <>
              {rows.length ? null : <EmptyMessage>Nenhum registro encontrado</EmptyMessage>}
            </>
          ) : (
            <LoadCenter>
              <Load />
            </LoadCenter>
          )}
          <CustomTablePagination
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
          />
        </TableContainer>
      </ResponsibleTableContent>
    </ResponsibleTable>
  );
}