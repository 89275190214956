import React from "react";

import {
    DashboardAnimation,
    DashboardTitle,
    DashboardText
} from "./styled";

import ContainerAuthenticated from "containers/Authenticated";

export default function DashboardHome() {

    return (
        <>
            <ContainerAuthenticated>

            </ContainerAuthenticated>
        </>
    );
}