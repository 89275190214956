import React, { useContext, useState } from "react";
import ContainerAuthenticated from "containers/Authenticated";
import Header from "components/Header";
import BasicTable from "components/Form/Table";
import { ContentTableAction, Icon, Content, ContentIcon } from "ui/styled";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import SearchComponent from "components/Search";
import FilterForms from "components/FilterForms";
import { downtimeReasonOptions, machineOptions } from "utils/options";
import { CoreContext } from "context/CoreContext";

export default function MachineMaintenance() {
  const history = useHistory();
  const navigate = (to) => history.push(`/${to}`);
  const [selected, setSelected] = useState([]);
  const [isActive, setIsActive] = useState(false);

  const { setModal } = useContext(CoreContext)

  const columns = [
    { title: 'MÁQUINA', ref: 'machine', align: 'right' },
    { title: 'MOTIVO DE PARADA', ref: 'reason' },
    { title: 'OBSERVAÇÃO', ref: 'observation' },
    { title: 'INÍCIO', ref: 'start' },
    { title: 'FIM', ref: 'end' },
    {
      title: 'AÇÕES',
      renderCell: ({ row }) => (
        <ContentTableAction>
          <ContentIcon onClick={() => { /* edit action */ }}>
            <Icon icon='edit' />
          </ContentIcon>
          <ContentIcon onClick={() => { /* delete action */ }}>
            <Icon icon='excluir' />
          </ContentIcon>
        </ContentTableAction>
      ),
    },
  ];

  const rows = [
    { machine: '123456789', reason: 'Descrição', observation: 'Descrição', start: '01/02/2024 - 12:34:56', end: '01/02/2024 - 12:34:56', id: 1 },
    { machine: '123456789', reason: 'Descrição', observation: 'Descrição', start: '01/02/2024 - 12:34:56', end: '01/02/2024 - 12:34:56', id: 2 },
    { machine: '123456789', reason: 'Descrição', observation: 'Descrição', start: '01/02/2024 - 12:34:56', end: '01/02/2024 - 12:34:56', id: 3 },
    { machine: '123456789', reason: 'Descrição', observation: 'Descrição', start: '01/02/2024 - 12:34:56', end: '01/02/2024 - 12:34:56', id: 4 },
    { machine: '123456789', reason: 'Descrição', observation: 'Descrição', start: '01/02/2024 - 12:34:56', end: '01/02/2024 - 12:34:56', id: 5 },
    { machine: '123456789', reason: 'Descrição', observation: 'Descrição', start: '01/02/2024 - 12:34:56', end: '01/02/2024 - 12:34:56', id: 6 },
    { machine: '123456789', reason: 'Descrição', observation: 'Descrição', start: '01/02/2024 - 12:34:56', end: '01/02/2024 - 12:34:56', id: 7 },
    { machine: '123456789', reason: 'Descrição', observation: 'Descrição', start: '01/02/2024 - 12:34:56', end: '01/02/2024 - 12:34:56', id: 8 },
    { machine: '123456789', reason: 'Descrição', observation: 'Descrição', start: '01/02/2024 - 12:34:56', end: '01/02/2024 - 12:34:56', id: 9 },
    { machine: '123456789', reason: 'Descrição', observation: 'Descrição', start: '01/02/2024 - 12:34:56', end: '01/02/2024 - 12:34:56', id: 10 },
    { machine: '123456789', reason: 'Descrição', observation: 'Descrição', start: '01/02/2024 - 12:34:56', end: '01/02/2024 - 12:34:56', id: 11 },
  ];
  const handleNavigate = () => {
    navigate('machine-maintenance/fabril');
  }
  const calendarFabril = () => {
    navigate('machine-maintenance/form');
  }


  const formItems = [
    {
      ref: 'maquina',
      type: 'select',
      placeholder: 'Máquina',
      options: machineOptions,
      onBlur: (value) => { console.log('Máquina blurred:', value); },
      full: true,
    },
    {
      ref: 'motivoParada',
      type: 'select',
      placeholder: 'Motivo de parada',
      options: downtimeReasonOptions,
      onBlur: (value) => { console.log('Motivo de parada blurred:', value); },
      full: true,
    },
    {
      ref: 'observacao',
      type: 'text',
      placeholder: 'Observação',
      onBlur: (value) => { console.log('Observação blurred:', value); },
      full: true,
    },
    {
      ref: 'dataInicio',
      type: 'date',
      placeholder: 'Data de início',
      onBlur: (value) => { console.log('Data de início blurred:', value); },
      full: true,
    },
    {
      ref: 'dataFim',
      type: 'date',
      placeholder: 'Data de fim',
      onBlur: (value) => { console.log('Data de fim blurred:', value); },
      full: true,
    }
  ];


  return (
    <ContainerAuthenticated>
      <Header title='Manutenção de máquinas' handleNavigate={handleNavigate} machineMaintenance calendarFabril={calendarFabril} calendarExclusion={() => setModal({ type: 'deleteFactorycalendar' })} />
      <Content>
        <SearchComponent totalResults={rows.length} onFilterClick={() => { setIsActive(!isActive) }} />
        <BasicTable columns={columns} rows={rows} selectable={{ selected, setSelected }} />
      </Content>
      <FilterForms formItems={formItems} isActive={isActive} setIsActive={setIsActive} />

    </ContainerAuthenticated>
  );
}