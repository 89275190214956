import React from 'react';
import { Contain, Content, FilterButton, ResultsCount, SearchContainer, SelectContainer } from './styled';
import Select from 'components/Form/Select';
import Button from 'components/Form/Button';

import { granttChart, granttChartMoeda } from 'utils/options';

export const GanttChartSearch = ({ totalResults, onFilterClick }) => {
  const [searchValue, setSearchValue] = React.useState('');

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
  };

  return (
    <SearchContainer>
      <Content>
        <SelectContainer>
          <Select options={granttChart} width={'150px'} radius={8} />
          <Select options={granttChartMoeda} width={'150px'} radius={8} />
        </SelectContainer>
        <Contain>
          <Button color={'blue'} width={'18%'}  >Monitorar</Button>
          <Button color={'blue'} width={'15%'}  >Filtrar</Button>
          <Button color={'blue'} width={'18%'} >Sequenciar</Button>
          <Button color={'blue'} width={'43%'} >Excluir sequenciamento</Button>
        </Contain>
      </Content>

    </SearchContainer>
  );
};

