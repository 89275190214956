import styled from "styled-components";

export const ModalBody = styled.div.attrs({
})`
    position: relative;
    z-index: 999;

`;
export const Overlay = styled.div.attrs({
})`
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: ${props => props.theme.palette.colors.overlay};
    border-radius: 12px;
    z-index: 1001;
    cursor: auto;
`;

export const ModalContainer = styled.div.attrs({
})`
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 9999;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;

`;

export const ModalContent = styled.div.attrs({
})`
    padding: 24px 16px;
    background: ${props => props.theme.palette.white.main};
    border-radius: 16px;
    z-index: 999;
    width: fit-content;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    max-height: 95vh;
    width: 1000px;
    animation:  slideDown 0.3s ease-out;
    @keyframes slideDown {
        from {
            opacity: 0;
            transform: translateY(-100%);
        }
        to {
            opacity: 1;
            transform: translateY(0);
        }
    }

    @keyframes slideUp {
        from {
            opacity: 1;
            transform: translateY(0);
        }
        to {
            opacity: 0;
            transform: translateY(-100%);
        }
    }
`;


export const MachineSelectionTitle = styled.div.attrs({
})`
    font-family: Poppins;
    font-size: 24px;
    font-weight: 700;
    line-height: 36px;
    text-align: left;
    color: ${props => props.theme.palette.colors.black};
    padding-bottom: 24px;
    width: 100%;
`;

export const ButtonContent = styled.div.attrs({
})`
    display: flex;
    gap: 16px;    
    width: 100%;
    justify-content: flex-start;
    margin-top: 24px;
`;


export const EditStopContainer = styled.div.attrs({
})`
    display: flex;
    justify-content: space-between;
    width: 100%;
    border-bottom: 1px solid ${props => props.theme.palette.colors.grey};

`;

export const Content = styled.div.attrs({
})`

`;

export const ButtonClose = styled.div.attrs({
})`
    cursor: pointer;
    height: fit-content;
    &:hover {
        opacity: 0.6;
    }
`;