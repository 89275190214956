import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import { TableHead } from '@mui/material';
import Button from '../Button';
import { ButtonContainer } from '../Button/styled';
import { CardTableHeader, CardTableHeaderTitle, ResponsibleTable, ResponsibleTableContent } from './styled';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.white.main,
    color: theme.palette.info.main,
    textTransform: 'upperCase'
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTable = styled(Table)({
  tableLayout: 'fixed',
});

const TableForm = ({ columns, rows, onAddRow, title }) => {
  const [newRow, setNewRow] = useState({});
  const [editRow, setEditRow] = useState(false);
  const keyLength = columns.length - 1;

  const handleInputChange = (e, ref) => {
    setNewRow({ ...newRow, [ref]: e.target.value });
  };

  const handleAddRow = () => {
    onAddRow(newRow);
    setNewRow({});
    setEditRow(!editRow);
  };
  const handleEditRow = () => {
    setEditRow(!editRow);
  }

  return (
    <ResponsibleTable>

      <ResponsibleTableContent>
        <TableContainer component={Paper} style={{ overflow: 'auto' }} >
          <CardTableHeader>
            <CardTableHeaderTitle>{title}</CardTableHeaderTitle>
          </CardTableHeader>
          <StyledTable sx={{ minWidth: 150 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                {columns.map((column, index) => (
                  <StyledTableCell key={index}
                    width={index === keyLength ? '120px' : index === 0 ? column.fullWidth ? '60%' : 'auto' : 'auto'}>
                    {(column.title)}
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, index) => (
                <TableRow key={index}>
                  {columns.map((column, colIndex) => (
                    <StyledTableCell key={colIndex} align={column.align || 'left'}>
                      {column.renderCell ? column.renderCell({ row }) : row[column.ref]}
                    </StyledTableCell>
                  ))}
                </TableRow>
              ))}
              {editRow && <TableRow>
                {columns.map((column, index) => (
                  <StyledTableCell key={index}>
                    {!column.renderCell && (
                      <TextField
                        fullWidth
                        variant="outlined"
                        value={newRow[column.ref] || ''}
                        onChange={(e) => handleInputChange(e, column.ref)}
                        size='small'
                      />
                    )}
                    {index === keyLength && (
                      <Button variant="contained" color={'green'} onClick={handleAddRow} >
                        Salvar
                      </Button>
                    )}
                  </StyledTableCell>
                ))}
              </TableRow>}
            </TableBody>
          </StyledTable>
          <ButtonContainer >
            <Button
              onClick={handleEditRow}
              color={'additional'}
              width={200}
            >
              {!editRow ? 'Adicionar novo' : 'Cancelar'}
            </Button>
          </ButtonContainer>
        </TableContainer>
      </ResponsibleTableContent>
    </ResponsibleTable >
  );
};

export default TableForm;
