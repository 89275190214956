import { TextField } from '@mui/material'
import styled from 'styled-components'

export const ResponsibleTable = styled.div.attrs({
})`

    overflow-x: auto;
    // display: grid;
    width: 100%;
`;


export const ResponsibleTableContent = styled.div.attrs({
})`
    min-width: fit-content;
`;

export const CardTableHeader = styled.div.attrs({
})`
    display: flex;
    align-items: center;
    gap: 12px;
    width: 100%;
`;

export const CardTableHeaderTitle = styled.div.attrs({
})`
    font-size:18px;
    font-weight: 500;
    color: ${props => props.theme.palette.colors.black}; 
    line-height: 24px;
`;