import styled from 'styled-components'

export const Content = styled.div.attrs({
})`
  width: 100%;           
    background: ${props => props.theme.palette.white.lightWhite};
    padding:2px 0 ;
`;

export const ButtonList = styled.button.attrs({

})`
    background: ${props => props.active ? props.theme.palette.green.main : props.theme.palette.white.main};
    border-radius: 2px;
    padding: 6px;
    margin: 2px 0;
    width: 100%;
    text-align: left;
    border: none;
    cursor: pointer;
    color:${props => props.active ? props.theme.palette.white.main : props.theme.palette.colors.fullgrey};

    &:hover {
        
         background: ${props => props.active ? '' : props.theme.palette.white.lightWhite};
        
        color: ${props => props => props.active ? '' : props.theme.palette.primary.main};
    }
`;