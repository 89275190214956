import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from "react";
import PropTypes from 'prop-types';
import {
    FormInput,
    FormWrapper,
    FormSeparator,
    ContentFormHeader,
    ContentForm,
    SelectContainer,
    ButtonContainer,
} from "./styled";
import Input, { MaskedInput } from "components/Form/Input";
import Select from "components/Form/Select";
import PasswordValidation from "components/Form/PasswordValidation";
import Button from "../Button";
import Check from "../Check";
import { ContentIcon, ContentTableAction, Icon } from "ui/styled";

export default forwardRef(function FormCore({ formItems, register, disabled, title, save }, ref) {

    const [form, setForm] = useState({})
    const formValue = ref => { return form?.[ref] ? form?.[ref] : ''; }
    const changeForm = (value, ref) => { setForm({ ...form, [ref]: value }); }

    const [nextBlur, setNextBlur] = useState(null)
    const [nextPrint, setNextPrint] = useState([])

    const scheduleBlur = (item) => {
        setTimeout(() => { setNextBlur(item); }, 1)
    }

    const safeBlur = (item) => {
        if (typeof item?.onBlur === "function") {
            item.onBlur()
        }
    }

    useEffect(() => {
        if (register) {
            setForm({ ...register })
        }
    }, [register])

    useEffect(() => {
        if (nextBlur) {
            safeBlur(nextBlur)
        }
    }, [nextBlur])

    useEffect(() => {
        setNextPrint([...formItems])
    }, [formItems])

    useImperativeHandle(ref, useCallback(() => ({
        getForm() { return form; }
    }), [form]))

    return (
        <>
            <ContentForm active={!!title}>
                <ContentFormHeader active={!!title}>{title}</ContentFormHeader>
                <FormWrapper>
                    {
                        nextPrint.map((item, key) => (
                            <>
                                {
                                    item.separator ? <FormSeparator key={key} /> :
                                        <FormInput
                                            full={item.full}
                                            half={item.half}
                                            quarter={item.quarter}
                                            twenty={item.twenty}
                                            twoThirds={item.twoThirds}
                                            oneThird={item.oneThird}
                                            sixThirth={item.sixThirth}
                                            key={key}
                                        >
                                            {
                                                item.custom ? item.custom
                                                    : item.options ?
                                                        <SelectContainer hasButton={item.hasButton}>
                                                            <Select
                                                                padding='8px'
                                                                collumn
                                                                holder
                                                                placeholder={item.placeholder}
                                                                options={item.options}
                                                                value={formValue(item.ref)}
                                                                onChange={val => { changeForm(val, item.ref); scheduleBlur(item); }}
                                                                disabled={item.disabled || disabled}
                                                                radius={'8px'}
                                                                width={'auto'}
                                                                paddingTop={'16px'}
                                                                full={item.hasButton}
                                                            />
                                                            {
                                                                item.hasButton &&
                                                                <ButtonContainer end>
                                                                    <Button small width={120} color={'green'} onClick={() => item.onButtonClick()}>
                                                                        {item.buttonLabel}
                                                                    </Button>
                                                                </ButtonContainer>
                                                            }

                                                        </SelectContainer>
                                                        : item.mask ?
                                                            <MaskedInput
                                                                mask={item.mask}
                                                                type={item.type}
                                                                placeholder={item.placeholder}
                                                                value={formValue(item.ref)}
                                                                onChange={e => changeForm(e.target.value, item.ref)}
                                                                onBlur={() => typeof item?.onBlur === "function" ? item.onBlur(formValue(item.ref)) : null}
                                                                disabled={item.disabled || disabled}
                                                            />
                                                            : item.passwordValidation ?
                                                                <PasswordValidation password={form.password} />
                                                                : item.type === 'checkbox' ?
                                                                    <Check
                                                                        checked={formValue(item.ref)}
                                                                        onChange={checked => { changeForm(checked, item.ref); scheduleBlur(item); }}
                                                                        label={item.placeholder}
                                                                        checkForm
                                                                        small
                                                                    />
                                                                    : item.type === 'button' ?
                                                                        <ContentTableAction>
                                                                            <ContentIcon center onClick={() => { /* edit action */ }}>
                                                                                <Icon icon='excluir' />
                                                                            </ContentIcon>
                                                                            <Button variant="contained" color={'green'} small >
                                                                                Salvar
                                                                            </Button>
                                                                        </ContentTableAction>
                                                                        :
                                                                        <Input
                                                                            noIcon
                                                                            type={item.type}
                                                                            placeholder={item.placeholder}
                                                                            value={formValue(item.ref)}
                                                                            width={'auto'}
                                                                            onChange={e => changeForm(e.target.value, item.ref)}
                                                                            disabled={item.disabled || disabled}
                                                                            onBlur={() => typeof item?.onBlur === "function" ? item.onBlur(formValue(item.ref)) : null}
                                                                            top='16px'
                                                                        />

                                            }
                                        </FormInput>
                                }
                            </>
                        ))
                    }
                </FormWrapper>
            </ContentForm>
        </>
    );
});
