import styled from "styled-components";

export const WeeklySetupContainer = styled.div.attrs({
})`
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 16px;
    background: ${props => props.theme.palette.white.lightWhite};
    border-radius: 16px;
`;

export const CalendarTitle = styled.div.attrs({
})`
    font-family: Poppins;
    font-size: 18px;
    font-weight: 600;
    line-height: 27px;
    text-align: left;
    color: ${props => props.theme.palette.colors.fullgrey};
`;

export const CalendarText = styled.div.attrs({
})`
    font-family: Poppins;
    font-size: 12px;
    font-weight: 300;
    line-height: 20px;
    letter-spacing: 0.5px;
    text-align: left;
    margin-bottom: 24px;
    color: ${props => props.theme.palette.colors.black};
`;

export const SetupContainer = styled.div.attrs({
})`
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 8px;
`;

