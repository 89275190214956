import React, { useState } from "react";
import ContainerAuthenticated from "containers/Authenticated";
import Header from "components/Header";
import BasicTable from "components/Form/Table";
import { ContentTableAction, Icon, Content, ContentIcon } from "ui/styled";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import SearchComponent from "components/Search";
import FilterForms from "components/FilterForms";
import { characteristicsOptions, characteristicsTypeOptions, dataTypeOptions } from "utils/options";

export default function Characteristics() {
  const history = useHistory();
  const navigate = (to) => history.push(`/${to}`);
  const [selected, setSelected] = useState([]);
  const [isActive, setIsActive] = useState(false);


  const columns = [
    { title: 'CÓDIGO', ref: 'code', align: 'right' },
    { title: 'Descrição', ref: 'description' },
    { title: 'Tipo', ref: 'type' },
    { title: 'Dado', ref: 'data' },
    {
      title: 'Ações',
      renderCell: ({ row }) => (
        <ContentTableAction>
          <ContentIcon onClick={() => { /* edit action */ }}>
            <Icon icon='edit' />
          </ContentIcon>
          <ContentIcon onClick={() => { /* delete action */ }}>
            <Icon icon='excluir' />
          </ContentIcon>
        </ContentTableAction>
      ),
    },
  ];

  const rows = [
    { code: '001', description: 'Produto 1', type: 'Tipo 1', data: 'Dado 1', id: 1 },
    { code: '002', description: 'Produto 2', type: 'Tipo 2', data: 'Dado 2', id: 2 },
    { code: '003', description: 'Produto 3', type: 'Tipo 3', data: 'Dado 3', id: 3 },
    { code: '003', description: 'Produto 3', type: 'Tipo 3', data: 'Dado 3', id: 3 },
    { code: '003', description: 'Produto 3', type: 'Tipo 3', data: 'Dado 3', id: 3 },
    { code: '003', description: 'Produto 3', type: 'Tipo 3', data: 'Dado 3', id: 3 },
    { code: '003', description: 'Produto 3', type: 'Tipo 3', data: 'Dado 3', id: 3 },
    { code: '003', description: 'Produto 3', type: 'Tipo 3', data: 'Dado 3', id: 3 },
    { code: '004', description: 'Produto 3', type: 'Tipo 3', data: 'Dado 3', id: 3 },
    { code: '004', description: 'Produto 3', type: 'Tipo 3', data: 'Dado 3', id: 3 },
    { code: '004', description: 'Produto 3', type: 'Tipo 3', data: 'Dado 3', id: 3 },

  ];

  const handleNavigate = () => {
    navigate(`characteristics/form`);
  }

  const formItems = [
    {
      ref: 'codigo',
      type: 'text',
      placeholder: 'Código',
      onBlur: (value) => { console.log('Código blurred:', value); },
      full: true,
    },
    {
      ref: 'descricao',
      type: 'text',
      placeholder: 'Descrição',
      onBlur: (value) => { console.log('Descrição blurred:', value); },
      full: true,

    },
    {
      ref: 'tipoCaracteristicas',
      type: 'select',
      placeholder: 'Tipo de características',
      options: characteristicsTypeOptions,
      onBlur: (value) => { console.log('Tipo de características blurred:', value); },
      full: true,

    },
    {
      ref: 'tipoDado',
      type: 'select',
      placeholder: 'Tipo de dado',
      options: dataTypeOptions,
      onBlur: (value) => { console.log('Tipo de dado blurred:', value); },
      full: true,

    }
  ];

  return (
    <ContainerAuthenticated>
      <Header title='Características' handleNavigate={handleNavigate} />
      <Content>
        <SearchComponent totalResults={rows.length} onFilterClick={() => { setIsActive(!isActive) }} />
        <BasicTable columns={columns} rows={rows} selectable={{ selected, setSelected }} />
      </Content>
      <FilterForms formItems={formItems} isActive={isActive} setIsActive={setIsActive} />

    </ContainerAuthenticated>
  );
}