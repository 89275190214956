import styledCmp from 'styled-components'
import ReactLoading from 'react-loading'

import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';

export const Load = styledCmp(ReactLoading).attrs(props => ({
    type: 'spin',
    color: props.outline ?
        (
            props.primary ? props.theme.palette.primary.main :
                (props.secondary ? props.theme.palette.secondary.main : props.theme.palette.info.main)
        )
        : props.theme.palette.primary.contrastText,
    height: 20,
    width: 20
}))`          
`;

export const ColorButton = styled(Button)(({ theme, nospace, small, big, width, margin, add }) => ({
    width: width ? width : '100%',
    height: small ? '35px' : big ? '56px' : '40px',
    marginTop: nospace ? '0px' : '12px',
    textTransform: 'none',
    margin: margin ? margin : '0',
    boxShadow: 'none',
    color: add ? theme.palette.addmachine.contrastText : '',
    border: add ? '1px solid ' + theme.palette.addmachine.main : '',
}));


export const ButtonContainer = styledCmp.div.attrs({
})`
    display: flex;
    justify-content: ${props =>
        props.spaceBetween ? 'space-between' :
            props.end ? 'flex-end' :
                props.start ? 'flex-start' :
                    'center'};
    width: 100%;
    padding: 16px;
`;
