import React, { useContext, useEffect, useState } from 'react'
import { ButtonClose, ButtonContent, ButtonEdit, CalendarText, CalendarTitle, CloseContainer, Content, EditStopContainer, EditText, EditTitle, HeaderCalendar, ModalBody, ModalContainer, ModalContent, Overlay, SetupContainer, WeeklySetupContainer, } from './styled'
import { CoreContext } from 'context/CoreContext'
import { Icon, Title } from 'ui/styled'
import Button from 'components/Form/Button'
import OptionsDays from 'components/OptionsDays'
import SmallCalendar from 'components/SmallCalendar'





export default function Calendar() {

    const { setModal } = useContext(CoreContext)
    const [isVisible, setIsVisible] = useState(true);

    const handleClicked = () => {
        setIsVisible(false);
        setModal(null)
    }

    return (
        <>
            <Overlay onClick={handleClicked} />
            <ModalContainer >
                <ModalBody>
                    <ModalContent isVisible={isVisible}>
                        <HeaderCalendar >
                            <Content>
                                <CalendarTitle>
                                    Selecione uma data
                                </CalendarTitle>
                                <CalendarText>
                                    03 Jan. 2024
                                </CalendarText>
                            </Content>
                            <ButtonEdit>
                                <Icon icon='pencil-big' />
                            </ButtonEdit>
                        </HeaderCalendar>
                        <SmallCalendar />
                        <ButtonContent>
                            <Button color={'blue'} link onClick={handleClicked} width={'90px'}>
                                Cancelar
                            </Button>
                            <Button color={'blue'} link width={'40px'}>
                                OK
                            </Button>
                        </ButtonContent>
                    </ModalContent>
                </ModalBody>
            </ModalContainer >
        </>
    )
}
