import { Touch } from "ui/styled";

const { default: styled } = require("styled-components");

export const SearchContainer = styled.div.attrs({
})`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  border-radius: 4px;
`;
export const Content = styled.div.attrs({

})`
  display: flex;
  align-items: center;
`;

export const ResultsCount = styled.div.attrs({

})`
  font-size: 14px;
  white-space: nowrap;
  margin-left: 16px;
  color: ${({ theme }) => theme.palette.info.main};
  
`;

export const FilterButton = styled(Touch).attrs({

})`
  display: flex;
  align-items: center;
  padding: 1px 15px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.palette.colors.lightgreen};
  border: 1px solid ${({ theme }) => theme.palette.colors.green};
`;