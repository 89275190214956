import React, { useContext, useState } from 'react'
import { InputContainer, Option, SelectAndInputContainer, SelectField, StyledInput } from './styled'
import { CoreContext } from 'context/CoreContext';

export default function SelectAndInput({ edit, options, stopDay }) {
  const [form, setForm] = useState({ hour: '', selectedOption: '' });
  const { setModal } = useContext(CoreContext)
  const handleChange = (event, ref) => {
    const { value } = event.target;
    const formattedValue = formatTime(value);
    changeForm(formattedValue, ref);
  };

  const handleSelectChange = (event) => {
    const { value } = event.target;
    setForm((prevForm) => ({ ...prevForm, selectedOption: value }));
  };

  const changeForm = (value, ref) => {
    setForm({ ...form, [ref]: value });
  };

  const formValue = (ref) => form?.[ref] || '';

  const formatTime = (value) => {
    const sanitizedValue = value.replace(/[^0-9:]/g, '');

    if (sanitizedValue.length === 2 && sanitizedValue[1] !== ':') {
      return `${sanitizedValue}:`;
    }
    if (sanitizedValue.length > 2 && !sanitizedValue.includes(':')) {
      return `${sanitizedValue.slice(0, 2)}:${sanitizedValue.slice(2, 4)}`;
    }
    if (sanitizedValue.length > 5) {
      return sanitizedValue.slice(0, 5);
    }
    return sanitizedValue;
  };


  return (
    <SelectAndInputContainer>
      <SelectField value={form.selectedOption} onChange={handleSelectChange} onClick={() => stopDay ? setModal({ type: 'calendar' }) : null} >
        {options?.map((option, index) => (
          <Option key={index} value={option.value} stopDay={stopDay}>
            {option.label}
          </Option>
        ))}
      </SelectField>
      <InputContainer edit={edit}>
        <StyledInput
          edit={edit}
          type="text"
          value={formValue('hour')}
          onChange={(e) => handleChange(e, 'hour')}
          placeholder="00:00"
          maxLength={5}
          pattern="^([01]\d|2[0-3]):([0-5]\d)$"
        />
      </InputContainer>
    </SelectAndInputContainer>
  )
}