import React, { useState } from "react";

import {

    CardHome,
    CardHomeHeader,
    CardHomeHeaderTitle,
    CardHomeBody,
    CardHomeHeaderActions,
    BackButton,
    CardHomeHeaderButton,
    ChevronButton

} from "./styled";

import Select from "components/Form/Select";
import { useHistory } from "react-router-dom";

export default function DashboardCardWrapper({ children, title, selectable, backable, closeable, actions, white, custom, loading }) {
    const history = useHistory()

    const [open, setOpen] = useState(true)

    return (
        <>
            <CardHome white={white}>
                {
                    (!title && !closeable && !backable && !selectable && !actions && !custom) ? null :
                        <CardHomeHeader>
                            <CardHomeHeaderButton>
                                <CardHomeHeaderTitle>{title}</CardHomeHeaderTitle>
                            </CardHomeHeaderButton>
                            {
                                !selectable ? null :
                                    <CardHomeHeaderActions>
                                        <Select options={selectable?.options || []} {...selectable} />
                                    </CardHomeHeaderActions>
                            }
                            {!custom ? null : custom}
                        </CardHomeHeader>
                }
                {
                    !open ? null :
                        <CardHomeBody>
                            {children}
                        </CardHomeBody>
                }
            </CardHome>
        </>
    );
}