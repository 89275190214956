import React, { useContext, useRef, useState } from "react";
import ContainerAuthenticated from "containers/Authenticated";
import Header from "components/Header";
import { Content } from "ui/styled";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { toast } from "react-toastify";
import { CoreContext } from "context/CoreContext";
import { Create, Update } from "services/core";
import { tipoProdutoOptions } from "utils/options";
import ProductsForms from "components/FormsConfig/ProductsForms";


export default function ProductForm() {
  const history = useHistory();
  const navigate = (to) => history.push(`/${to}`);
  const [saving, setSaving] = useState(false);
  const { currentCompany } = useContext(CoreContext);


  const refForm = useRef();

  const cancel = () => {
    history.goBack();

  }

  const success = () => {
    toast.success('Evento salvo com sucesso');
    history.goBack();
  }


  const formItems = [
    {
      ref: 'codigo',
      type: 'text',
      placeholder: 'Código',
      onBlur: (value) => { console.log('Código blurred:', value); },
      quarter: true
    },
    {
      ref: 'descricao',
      type: 'text',
      placeholder: 'Descrição',
      onBlur: (value) => { console.log('Descrição blurred:', value); },
      half: true
    },
    {
      ref: 'consumoPorVolume',
      type: 'checkbox',
      placeholder: 'Consumo por volume',
      onChange: (checked) => { console.log('Consumo por volume:', checked); },
      quarter: true
    },
    {
      ref: 'tipoProduto',
      options: tipoProdutoOptions,
      placeholder: 'Tipo de produto',
      onBlur: (value) => { console.log('Tipo de produto selecionado:', value); },
      quarter: true
    },
    {
      ref: 'unidade',
      type: 'text',
      placeholder: 'Unidade',
      onBlur: (value) => { console.log('Unidade blurred:', value); },
      quarter: true
    },
    {
      ref: 'leadTimeCompra',
      type: 'text',
      placeholder: 'Lead time compra (dias)',
      onBlur: (value) => { console.log('Lead time compra (dias) blurred:', value); },
      quarter: true
    },
    {
      ref: 'estoqueMinimo',
      type: 'text',
      placeholder: 'Estoque mínimo',
      onBlur: (value) => { console.log('Estoque mínimo blurred:', value); },
      quarter: true
    }
  ];
  const save = async () => {
    const form = refForm.current.getForm();
    const payload = {
      ...form,
    };

    delete payload.id;

    if (!valid(payload, formItems)) {
      toast.error("Preencha todos os campos");
      return;
    }

    setSaving(true);
    const result = currentCompany?.id ? await Update({ data: payload }, currentCompany?.id) : await Create({ data: payload });

    if (result && !result.error) {
      success();
    } else {
      toast.error("Erro ao salvar, tente novamente mais tarde");
    }
    // success();
    setSaving(false);
  };

  const valid = (payload, array) => {
    for (let item of array) {
      if (item?.ref && !payload?.[item?.ref]) {
        return false;
      }
    }
    return true;
  };
  return (
    <ContainerAuthenticated>
      <Header title={'Novo produto'} icon cancel={cancel} save={save} />
      <Content>
        <ProductsForms formItems={formItems} saving={saving} refForm={refForm} />
      </Content>
    </ContainerAuthenticated>
  );
}