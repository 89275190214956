import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  mode: 'light',
  palette: {
    primary: {
      main: '#262626',
      contrastText: '#fff',
      grey: '#4B4B4B',
      lightGrey: '#E1E1E1',
      blue: '#113167',
    },
    secondary: {
      main: '#e86c30',
      contrastText: '#fff',
    },
    white: {
      main: '#FFFFFF',
      contrastText: '#20B273',
      lightWhite: '#F5F5F5'
    },
    error: {
      main: '#FF0000',
      border: '#EB9C9C',
      background: '#fff0ee',
    },
    gradient: {
      main: '#ECEBE4',
      secondary: '#D3F1E4',
      tertiary: '#FFE1DE',
      quaternary: '#EAE9E1',

    },
    lightGrey: {
      main: '#DDDDDD',
      contrastText: '#000000',
    },
    success: {
      main: '#49BF00',
      border: '#9EFE7E',
      background: '#F5FFEB',
    },
    cancel: {
      main: 'rgba(207, 214, 225, 1)',
      contrastText: '#000000',
    },
    blue: {
      main: '#113167',
      contrastText: '#ffffff',
    },
    addmachine: {
      main: '#20B273',
      contrastText: '#113167',
    },
    green: {
      main: '#20B273',
      contrastText: '#ffffff',
    },

    red: {
      main: '#F83446',
      contrastText: '#ffffff',
    },

    warning: {
      main: '#ffa726',
    },
    info: {
      main: '#a4a4a4',
      contrastText: '#fff',
    },
    additional: {
      main: '#E8EBF0',
      contrastText: '#000000',
    },
    colors: {
      white: '#ffffff',
      green: '#20B273',
      black: '#000000',
      grey: '#DDDDDD',
      fullgrey: '#5E5E5E',
      backgroundgrey: '#F7F7F7',
      lightgrey: '#727272',
      almostlightgray: '#E8E8E8',
      shadow: 'rgba(0,0,0,.16)',
      lightshadow: 'rgba(112,112,112,.06)',
      blue: '#113167',
      lightgreen: 'rgba(121, 209, 171, 0.2)',
      red: '#FF003D',
      lightPink: '#FFF0EE',
      overlay: 'rgba(0, 0, 0, 0.6)',

    }
  },
});  