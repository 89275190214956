import styled from "styled-components";

export const DashboardTitle = styled.div.attrs({
})`            
    font-size: 22px;
    font-weight: bold;
    color: ${props => props.theme.palette.colors.black};
    margin-bottom: 12px;
    ${props => props.centred ? `
            text-align: center;
        ` : ``
    }
`;

export const MachineMaintenanceContainer = styled.div.attrs({
})`
    display: flex;
    width: 100%;
    flex-direction: column;
    background: ${props => props.theme.palette.white.lightWhite};
    border-radius: 16px;
    padding: 23px 24px;
    margin: 24px 0;
`;

export const MachineMaintenanceTitle = styled.div.attrs({
})`
    font-family: Poppins;
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
    text-align: left;
    color: ${props => props.theme.palette.colors.black};
`;

export const MachineMaintenaceHeader = styled.div.attrs({
})`
    display: flex;
    width: 100%;
    border-top: 1px solid ${props => props.theme.palette.primary.lightGrey};    
`;

export const MachineMaintenaceHeaderItem = styled.div.attrs({
})`
    display: flex;
    flex-direction: column;
    gap: 11px;
    ${props => props.flex && `
        flex: 1;
    `}
`;

export const MachineMaintenaceHeaderText = styled.div.attrs({
})`
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 22.4px;
    text-align: left;
    color: ${props => props.theme.palette.primary.grey};
    padding: 17px 0;
    border-bottom: 1px solid ${props => props.theme.palette.primary.lightGrey};
    text-transform: uppercase;
`;

export const ButtonContent = styled.div.attrs({
})`
    padding-right: 16px;
`;

export const StyledInput = styled.input.attrs({
})`

  border: 1px solid ${props => props.theme.palette.primary.lightGrey};
  border-radius: 4px;
  font-size: 16px;
  padding: 5px;
  width: 184px;
  border: 'none'
  box-sizing: border-box;
  background-color: ${props => props.theme.palette.primary.lightGrey};
  ${props => props.full && `
    width: 100%;
    `};
    &:focus {
    border-color: ${props => props.theme.palette.primary.lightGrey};
    outline: none;
  }
`;


export const CalendarContainer = styled.div.attrs({
})`
    background: ${props => props.theme.palette.primary.lightGrey};
    padding: 16px;
    width: 100%;
    display: flex;
    gap: 16px;
    justify-content: space-between;
    margin-top: 11px;
`;