import React, { useState } from "react";
import { companyOptions, newScenarioNameOptions, scenarioOptions } from "utils/options";
import ScenarioParametersForms from "components/FormsConfig/ScenarioParametersForms";
import { Container, CopyContainer, FormSeparator, FormsContainer, Title } from "./styled";
import Button from "components/Form/Button";
import { Icon } from "ui/styled";

export default function ScenarioParametersCopy() {
  const [isActive, setIsActive] = useState(true);

  const formItems = [
    {
      ref: 'empresa',
      type: 'select',
      placeholder: 'Empresa',
      options: companyOptions,
      onBlur: (value) => { console.log('Empresa blurred:', value); },
    },
    {
      ref: 'cenario',
      type: 'select',
      placeholder: 'Cenário',
      options: scenarioOptions,
      onBlur: (value) => { console.log('Cenário blurred:', value); },
    },
    {
      ref: 'nomeNovoCenario',
      type: 'select',
      placeholder: 'Nome do novo cenário',
      options: newScenarioNameOptions,
      onBlur: (value) => { console.log('Nome do novo cenário blurred:', value); },

    }
  ];

  return (
    <>
      <FormsContainer >
        <Container onClick={() => setIsActive(!isActive)}>
          <Title>Copiar dados de outro cenário</Title>
          <Icon icon={isActive ? 'chevron-top' : 'chevron-down'} width={23} />
        </Container>
        {
          isActive &&
          <CopyContainer>
            <FormSeparator />
            <ScenarioParametersForms formItems={formItems} />
            <FormSeparator />
            <Button width='108px' color={'green'} small onClick={() => {/*action copy */ }}>Copiar</Button>
          </CopyContainer>
        }
      </FormsContainer>
    </>
  );
}