import React from 'react'
import { CalendarText, CalendarTitle, SetupContainer, WeeklySetupContainer } from './styled'
import Button from 'components/Form/Button'
import { Icon } from 'ui/styled'
import OptionsDays from 'components/OptionsDays'

export default function WeeklySetup() {
  const items = [
    {},
    {},
    {},
    {},
  ]

  const options = [
    { value: '', label: 'Segunda-feira' },
    { value: 'option1', label: 'Option 1' },
    { value: 'option2', label: 'Option 2' },
    { value: 'option3', label: 'Option 3' },
  ];
  return (
    <>
      <WeeklySetupContainer>
        <CalendarTitle>
          Configuração semanal
        </CalendarTitle>
        <CalendarText>
          Adicione, edite ou exclua um horário de parada e retorno, e salve as alterações para inseri-las no calendário do mês ao lado.
        </CalendarText>
        <SetupContainer>
          {
            items.map((item, index) => (<OptionsDays key={index} options={options} />)
            )
          }
          <Button link color={'blue'} width={'302px'}>
            <Icon icon='plus' />
            Adicionar novo dia
          </Button>
        </SetupContainer>
        <Button color={'blue'}>
          Aplicar na programação
        </Button>
        <Button color={'cancel'} margin={'16px 0 0 '}>
          Excluir todos
        </Button>
      </WeeklySetupContainer>
    </>
  )
}
