import React, { useContext, useRef, useState } from "react";
import ContainerAuthenticated from "containers/Authenticated";
import Header from "components/Header";
import { Content } from "ui/styled";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { toast } from "react-toastify";
import { CoreContext } from "context/CoreContext";
import { Create, ReadOne, Update } from "services/core";
import { caracteristicaOptions, dadoOptions } from "utils/options";
import CharacteristicForm from "components/FormsConfig/CharacteristicsForm";


export default function ProcessesForm() {
  const history = useHistory();
  const navigate = (to) => history.push(`/${to}`);
  const [saving, setSaving] = useState(false);
  const { currentCompany } = useContext(CoreContext);


  const refForm = useRef();

  const cancel = () => {
    history.goBack();

  }

  const success = () => {
    toast.success('Evento salvo com sucesso');
    history.goBack();
  }
  const formItems = [
    {
      ref: 'codigo',
      type: 'text',
      placeholder: 'Código',
      onBlur: (value) => { console.log('Código blurred:', value); },
      oneThird: true
    },
    {
      ref: 'descricao',
      type: 'text',
      placeholder: 'Descrição',
      onBlur: (value) => { console.log('Descrição blurred:', value); },
      twoThirds: true
    },
  ];
  const save = async () => {
    const form = refForm.current.getForm();
    const payload = {
      ...form,
    };

    delete payload.id;

    if (!valid(payload, formItems)) {
      toast.error("Preencha todos os campos");
      return;
    }

    setSaving(true);
    const result = currentCompany?.id ? await Update({ data: payload }, currentCompany?.id) : await Create({ data: payload });

    if (result && !result.error) {
      success();
    } else {
      toast.error("Erro ao salvar, tente novamente mais tarde");
    }
    // success();
    setSaving(false);
  };

  const valid = (payload, array) => {
    for (let item of array) {
      if (item?.ref && !payload?.[item?.ref]) {
        return false;
      }
    }
    return true;
  };
  return (
    <ContainerAuthenticated>
      <Header title={'Processos'} icon cancel={cancel} save={save} />
      <Content>
        <CharacteristicForm formItems={formItems} saving={saving} refForm={refForm} />
      </Content>
    </ContainerAuthenticated>
  );
}