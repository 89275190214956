import React, { useMemo, useState } from "react";
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';


import {
    FormTitle,
    FormText,
    FormSpacer,
    FormContent
} from './styled'

import Button from "components/Form/Button";
import Input from 'components/Form/Input'

import ContainerUnauthenticated from "containers/Unauthenticated";
import { DoForgotPassword } from "services/authentication";
import { exposeStrapiError } from "utils";
import { Content } from "containers/Landpage/styled";
import { ButtonContainer } from "components/Form/Button/styled";
import { ContentIcon, Icon } from "ui/styled";

export default function Forgot() {
    const history = useHistory();
    const navigate = to => history.push(`/${to}`);

    const [loading, setLoading] = useState(false)

    const [form, setForm] = useState({})
    const formValue = ref => { return form?.[ref] ? form?.[ref] : ''; }
    const changeForm = (value, ref) => { setForm({ ...form, [ref]: value }); }
    const [step, setStep] = useState(0)

    const valid = (verbose = false) => {

        if (!formValue('email') || !formValue('email').length) {
            if (verbose) { toast.error('Preencha o campo: Email'); }
            return false;
        }

        return true
    }

    const action = async () => {
        if (!valid(true)) { return; }
        setLoading(true)

        const result = await DoForgotPassword({ email: formValue('email')?.replace(/ /g, '') })

        setLoading(false)
        // if (result && !exposeStrapiError(result)) {
        next()
        // }
    }


    const steps = useMemo(() => [
        {
            title: 'Esqueceu sua senha?',
            text: 'Digite seu e-mail cadastrado para recuperar sua senha',
            label: 'Voltar',
            labelNext: 'Continuar',
        },
        {
            title: 'Verifique seu email',
            text: 'Acesse o link de recuperação que foi enviado no seu email cadastrado',
            label: 'Fechar',
        },

    ], []);
    const next = () => {
        if (step === steps.length - 1) {
            //save data
            navigate('Login')
            return;
        }
        setStep(step + 1);
    }
    const back = () => {
        if (step === 0) {
            navigate('Login')
            return;
        }
        setStep(step - 1);
    }
    return (
        <>
            <ContainerUnauthenticated green logo centred start>
                {!(step === 0) ? null : <> <FormContent>
                    <FormTitle>{steps?.[step]?.title}</FormTitle>
                    <FormText>{steps?.[step]?.text}</FormText>
                    <Input placeholder="Email" id={'email'} value={formValue('email')} onChange={e => changeForm(e.target.value, 'email')} width='100%' grey />
                    <FormSpacer />
                    <Content>
                        <Button width='50%' smal color={'white'} iconPosition="left" start nospace onClick={() => history.goBack()}>
                            <ContentIcon>
                                <Icon
                                    icon='chevron-left' />
                                {steps?.[step]?.label}
                            </ContentIcon>
                        </Button>
                        <Button width='50%' iconPosition="right" color={'green'} nospace loading={loading} onClick={action} >
                            <ContentIcon between>
                                {steps?.[step]?.labelNext}
                                <Icon icon='chevron-right' />
                            </ContentIcon>
                        </Button>
                    </Content>
                </FormContent>
                </>
                }
                {
                    !(step === 1) ? null : <>
                        <FormContent>
                            <FormTitle green>{steps?.[step]?.title}</FormTitle>
                            <FormText>{steps?.[step]?.text}</FormText>
                            <FormSpacer />
                            <ButtonContainer>
                                <Button width='50%' color={'green'} nospace loading={loading} onClick={next} >{steps?.[step]?.label}</Button>
                            </ButtonContainer>
                        </FormContent>
                    </>
                }
            </ContainerUnauthenticated>
        </>
    );
}