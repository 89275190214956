import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import DashboardCardWrapper from "components/Wrapper";
import FormCore from "../../Form/Core";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { CoreContext } from "context/CoreContext";
import { ReadOne } from "services/core";

export default function MachineFormsSecondary({ formItems, saving, refForm, save }) {

  const history = useHistory();
  const navigate = (to) => history.push(`/${to}`);


  const { id } = useParams();
  const { currentCompany } = useContext(CoreContext);


  const [loading, setLoading] = useState(false);
  const [register, setRegister] = useState(null);

  const init = async () => {
    setLoading(true);
    const result = await ReadOne(currentCompany?.id);
    if (result?.data?.id) {
      setRegister({
        ...result.data
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    if (currentCompany?.id) {
      init();
    }
  }, [currentCompany]);

  return (
    <>
      <DashboardCardWrapper white title={"Operadores"} loading={saving}>
        <FormCore formItems={formItems} register={register} ref={refForm} save />
      </DashboardCardWrapper>
    </>
  );
}