import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { CalendarContainer, CalendarGrid, CalendarHeader, CalendarText, CalendarTitle, DayCell, DayCellContent, DayLabel, EditIcon, EventContent, EventFake, EventItem, EventItemTitle, EventList, ExcludeButton, MonthYear, NavButton, Navigation } from './styled';
import { Content, Icon } from 'ui/styled';
import Button from 'components/Form/Button';
import { CoreContext } from 'context/CoreContext';

// Array containing the names of the months in Portuguese
const monthNames = [
  "Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho",
  "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"
];

// Array containing the names of the days of the week in Portuguese
const weekDayNames = ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"];

export default function CalendarComponent() {
  const { setModal } = useContext(CoreContext);

  // Initialize state for the current month and year
  const today = new Date();
  const [currentMonth, setCurrentMonth] = useState(today.getMonth());
  const [currentYear, setCurrentYear] = useState(today.getFullYear());

  // Initialize state for events with start and end times
  const [events, setEvents] = useState([
    {
      Seg: [
        { end: '18:00' },
        { end: '20:00' },
        { start: '06:00', end: '08:00' },

      ],
      Ter: [
        { start: '06:00' },
        { start: '08:00' },
        { end: '08:00' },
      ]
    }
  ]);

  // Calculate the number of days in the current month
  const daysInMonth = new Date(currentYear, currentMonth + 1, 0).getDate();
  // Determine the day of the week for the first day of the month
  const firstDayOfMonth = new Date(currentYear, currentMonth, 1).getDay();

  // Function to go to the next month
  const handleNextMonth = () => {
    if (currentMonth === 11) {
      setCurrentMonth(0);
      setCurrentYear(currentYear + 1);
    } else {
      setCurrentMonth(currentMonth + 1);
    }
  };

  // Function to go to the previous month
  const handlePrevMonth = () => {
    if (currentMonth === 0) {
      setCurrentMonth(11);
      setCurrentYear(currentYear - 1);
    } else {
      setCurrentMonth(currentMonth - 1);
    }
  };

  // Helper function to check if there are any events with both start and end times for a given day
  const hasStartAndEnd = (events, weekDayName) => {
    return events.some(event =>
      event[weekDayName]?.some(item => item.start && item.end)
    );
  };

  // Function to render the days of the calendar
  const renderCalendarDays = () => {
    const cells = [];
    // Add empty cells for days before the first day of the month
    for (let i = 0; i < firstDayOfMonth; i++) {
      cells.push(<DayCell key={`empty-${i}`} />);
    }

    // Render each day of the month
    for (let day = 1; day <= daysInMonth; day++) {
      const currentDate = new Date(currentYear, currentMonth, day);
      const weekDay = currentDate.getDay();
      const weekDayName = weekDayNames[weekDay];
      const isSunday = weekDay === 0;

      // Get all events for the current day
      const dayEvents = events.flatMap(event => event[weekDayName] || []);
      // Check if there are any events with both start and end times
      const hasStartAndEnd = dayEvents.some(item => item.start && item.end);

      cells.push(
        <DayCell key={day} isSunday={isSunday}>
          <DayCellContent>
            <DayLabel isSunday={isSunday}>{`${day.toString().padStart(2, '0')} ${weekDayName}`}</DayLabel>
            <EditIcon alt='edit' onClick={() => setModal({ type: 'editstopforday' })} />
          </DayCellContent>
          <EventList>
            {dayEvents.map((item, index) => {
              // Determine if the EventContent should be centered
              const centerContent = item.start && item.end && hasStartAndEnd;
              return (

                <EventContent center={centerContent} key={index}>
                  {item.start ? (
                    <EventItemTitle start={!centerContent} onClick={() => setModal({ type: 'editstop' })}  >
                      {item.start}
                    </EventItemTitle>
                  ) : (
                    <EventFake />
                  )}
                  {item.end ? (
                    <EventItemTitle start={centerContent} onClick={() => setModal({ type: 'editstop' })}>
                      {item.end}
                    </EventItemTitle>
                  ) : (
                    <EventFake />
                  )}
                </EventContent>

              );
            })}
          </EventList>
        </DayCell>
      );
    }

    return cells;
  };

  return (
    <CalendarContainer>
      <CalendarTitle>
        Programação do Mês
      </CalendarTitle>
      <CalendarText>
        Adicione, edite ou exclua um horário de parada e retorno.
      </CalendarText>
      <CalendarHeader>
        <Navigation>
          <NavButton onClick={handlePrevMonth} rotation>
            <Icon icon='arrow-left' />
          </NavButton>
          <MonthYear>{`${monthNames[currentMonth]} ${currentYear}`}</MonthYear>
          <NavButton onClick={handleNextMonth}><Icon icon='arrow-left' /></NavButton>
        </Navigation>
        <Button width={'fit-content'} small color={'cancel'} onClick={() => setModal({ type: 'clearscheedule' })}
        >Excluir todos</Button>
      </CalendarHeader>
      <CalendarGrid>
        {renderCalendarDays()}
      </CalendarGrid>
    </CalendarContainer>
  );
}
