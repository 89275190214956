import React, { useContext, useEffect, useState } from 'react'
import { ButtonClose, ButtonContent, EditText, EditTitle, CloseContainer, Content, EditStopContainer, ModalBody, ModalContainer, ModalContent, Overlay, SetupContainer, WeeklySetupContainer, } from './styled'
import { CoreContext } from 'context/CoreContext'
import { Icon, Title } from 'ui/styled'
import Button from 'components/Form/Button'
import OptionsDays from 'components/OptionsDays'





export default function ClearScheedule() {

    const { setModal } = useContext(CoreContext)
    const [isVisible, setIsVisible] = useState(true);

    const items = [
        {},
        {},

    ]
    const handleClicked = () => {
        setIsVisible(false);
        setModal(null)
    }

    const options = [
        { value: '', label: 'Terça-feira - 03/01/2024' },
        { value: 'option1', label: 'Option 1' },
        { value: 'option2', label: 'Option 2' },
        { value: 'option3', label: 'Option 3' },
    ];
    return (
        <>
            <Overlay onClick={handleClicked} />
            <ModalContainer >
                <ModalBody>
                    <ModalContent isVisible={isVisible}>
                        <WeeklySetupContainer>
                            <EditStopContainer>
                                <Content>
                                    <EditTitle>
                                        Deseja mesmo limpar a programação?
                                    </EditTitle>
                                    <EditText>
                                        Esta ação apagará todos os registros do mês permanentemente.
                                    </EditText>
                                </Content>
                                <ButtonClose >
                                    <Icon icon='close-big' onClick={handleClicked} />
                                </ButtonClose>
                            </EditStopContainer>
                        </WeeklySetupContainer>
                        <ButtonContent>
                            <Button color={'cancel'} onClick={handleClicked}>
                                Cancelar
                            </Button>
                            <Button color={'blue'}>
                                confirmar
                            </Button>
                        </ButtonContent>
                    </ModalContent>
                </ModalBody>
            </ModalContainer >
        </>
    )
}
