import React, { useContext } from 'react'
import { CoreContext } from 'context/CoreContext'
import { ThemedComponent } from 'ui/theme'
import EditStopForDay from '../EditStopForDay'
import EditStop from '../EditStop'
import ClearScheedule from '../ClearScheedule'
import MachineSelection from '../MachineSelection'
import DeleteFactoryCalendar from '../DeleteFactoryCalendar'
import Calendar from '../Calendar'


export default function ModalCore() {

    const { modal } = useContext(CoreContext)

    return (
        <>
            <ThemedComponent>
                {modal?.type === 'editstopforday' ? <EditStopForDay /> : null}
                {modal?.type === 'editstop' ? <EditStop /> : null}
                {modal?.type === 'clearscheedule' ? <ClearScheedule /> : null}
                {modal?.type === 'machineselection' ? <MachineSelection /> : null}
                {modal?.type === 'deleteFactorycalendar' ? <DeleteFactoryCalendar /> : null}
                {modal?.type === 'calendar' ? <Calendar /> : null}
            </ThemedComponent>
        </>
    )
}