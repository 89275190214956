import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import FormCore from "../Form/Core";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { CoreContext } from "context/CoreContext";
import { ReadOne } from "services/core";
import { FilterSidebar, FormSpacing, Title, Overlay } from "./styled";
import Button from "components/Form/Button";
import { ContentIcon, Icon } from "ui/styled";

export default function FilterForms({ formItems, refForm, isActive, setIsActive }) {
  const history = useHistory();
  const navigate = (to) => history.push(`/${to}`);

  const { id } = useParams();
  const { currentCompany } = useContext(CoreContext);

  const [loading, setLoading] = useState(false);
  const [register, setRegister] = useState(null);

  const init = async () => {
    setLoading(true);
    const result = await ReadOne(currentCompany?.id);
    if (result?.data?.id) {
      setRegister({
        ...result.data
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    if (currentCompany?.id) {
      init();
    }
  }, [currentCompany]);

  const toggleSidebar = () => {
    setIsActive(!isActive);
  };

  return (
    <>
      <FilterSidebar className={isActive ? 'active' : ''}>
        <ContentIcon between>
          <Title>Filtros</Title>
          <Icon icon='close' onClick={toggleSidebar} />
        </ContentIcon>
        <FormSpacing />
        <FormCore formItems={formItems} register={register} ref={refForm} />
        <FormSpacing />
        <Button color={'green'} onClick={init}>Filtrar</Button>
        <Button onClick={init} primary outline margin={'10px 0'} >Limpar</Button>
      </FilterSidebar>
      <Overlay className={isActive ? 'active' : ''} onClick={toggleSidebar} />
    </>
  );
}