import styled from "styled-components";

export const ModalBody = styled.div.attrs({
})`
    position: relative;
    z-index: 999;

`;
export const Overlay = styled.div.attrs({
})`
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: ${props => props.theme.palette.colors.overlay};
    border-radius: 12px;
    z-index: 1001;
    cursor: auto;
`;

export const ModalContainer = styled.div.attrs({
})`
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 9999;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;

`;

export const ModalContent = styled.div.attrs({
})`
    background: ${props => props.theme.palette.white.lightWhite};
    border-radius: 16px;
    z-index: 999;
    width: fit-content;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    max-height: 90vh;
    animation:  slideDown 0.3s ease-out;
    @keyframes slideDown {
        from {
            opacity: 0;
            transform: translateY(-100%);
        }
        to {
            opacity: 1;
            transform: translateY(0);
        }
    }

    @keyframes slideUp {
        from {
            opacity: 1;
            transform: translateY(0);
        }
        to {
            opacity: 0;
            transform: translateY(-100%);
        }
    }
`;

export const WeeklySetupContainer = styled.div.attrs({
})`
    display: flex;
    flex-direction: column;
    flex: 1;
`;

export const CalendarTitle = styled.div.attrs({
})`
    font-family: Poppins;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.10000000149011612px;
    text-align: left;
    color: ${props => props.theme.palette.colors.fullgrey};
`;

export const CalendarText = styled.div.attrs({
})`
    font-family: Poppins;
    font-size: 32px;
    font-weight: 400;
    line-height: 40px;
    text-align: left;
    color: ${props => props.theme.palette.colors.blue};
`;

export const SetupContainer = styled.div.attrs({
})`
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 8px;
`;

export const EditStopContainer = styled.div.attrs({
})`
    display: flex;
    justify-content: space-between;
    max-width: 448px;
    
`;

export const Content = styled.div.attrs({
})`

`;

export const ButtonContent = styled.div.attrs({
})`
    display: flex;
    width: 100%;
    justify-content: flex-end;
    padding-right: 12px;
    padding-bottom: 12px;

`;


export const ButtonEdit = styled.div.attrs({
})`
    cursor: pointer;
    width: 40px;
    height: 40px;
    &:hover {
        opacity: 0.6;
    }
`;

export const HeaderCalendar = styled.div.attrs({
})`
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid ${props => props.theme.palette.colors.grey};
    padding: 12px 12px  12px 24px;
`;