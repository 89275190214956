import React, { } from "react";

import { ButtonList, Content } from "./styled";

export default function List(props) {

  const handleClick = () => {
    props.onClick();
  };

  return (
    <>
      <Content>
        {<ButtonList active={props.active} onClick={handleClick}>{props.title}</ButtonList>}
      </Content>
    </>
  );
}