import React, { useState } from "react";
import ContainerAuthenticated from "containers/Authenticated";
import Header from "components/Header";

import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import ScenarioParametersCopy from "components/ScenarioParametersCopy";
import ScenarioParametersButton from "components/ScenarioParametersButton";

export default function ScenarioParameters() {
  const history = useHistory();
  const navigate = (to) => history.push(`/${to}`);


  const handleNavigate = () => {
    navigate(`characteristics/form`);
  }
  const buttons = [
    {
      label: 'Download exemplo CSV',
      color: 'green',
      onClick: () => { console.log('Download exemplo CSV'); }
    },
    {
      label: 'Importar EPs',
      color: 'green',
      onClick: () => { console.log('Importar EPs'); }
    }
  ]
  const buttonsProducao = [
    {
      label: 'Importar OPs',
      color: 'green',
      onClick: () => { console.log('Importar OPs'); }
    },
    {
      label: 'Excluir OPs',
      color: 'red',
      onClick: () => { console.log('Excluir OPs'); }
    }
  ]
  const buttonsCenario = [
    {
      label: 'Importar cenário',
      color: 'green',
      onClick: () => { console.log('Importar OPs'); }
    },
  ]

  return (
    <ContainerAuthenticated>
      <Header
        title="Parâmetros de cenário"
        noButton
      />
      <ScenarioParametersCopy />
      <ScenarioParametersButton title="Importar estrutura de produto" buttons={buttons} />

      <ScenarioParametersButton title="Importar ordem de produção" buttons={buttonsProducao} />
      <ScenarioParametersButton title="Importar cenário" buttons={buttonsCenario} />

    </ContainerAuthenticated>
  );
}