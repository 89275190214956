import React, { useState } from "react";
import ContainerAuthenticated from "containers/Authenticated";
import Header from "components/Header";
import BasicTable from "components/Form/Table";
import { ContentTableAction, Icon, Content, ContentIcon } from "ui/styled";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import SearchComponent from "components/Search";
import FilterForms from "components/FilterForms";
import { typeOptions } from "utils/options";

export default function Products() {
  const history = useHistory();
  const navigate = (to) => history.push(`/${to}`);
  const [selected, setSelected] = useState([]);
  const [isActive, setIsActive] = useState(false);

  const columns = [
    { title: 'CÓDIGO', ref: 'code', align: 'right' },
    { title: 'DESCRIÇÃO', ref: 'description' },
    { title: 'TIPO', ref: 'type' },
    {
      title: 'AÇÕES',
      renderCell: ({ row }) => (
        <ContentTableAction>
          <ContentIcon onClick={() => { /* edit action */ }}>
            <Icon icon='edit' />
          </ContentIcon>
          <ContentIcon onClick={() => { /* delete action */ }}>
            <Icon icon='excluir' />
          </ContentIcon>
        </ContentTableAction>
      ),
    },
  ];

  const rows = [
    { code: '123456789', description: 'Descrição', type: 'Descrição', id: 1 },
    { code: '123456789', description: 'Descrição', type: 'Descrição', id: 2 },
    { code: '123456789', description: 'Descrição', type: 'Descrição', id: 3 },
    { code: '123456789', description: 'Descrição', type: 'Descrição', id: 4 },
    { code: '123456789', description: 'Descrição', type: 'Descrição', id: 5 },
    { code: '123456789', description: 'Descrição', type: 'Descrição', id: 6 },
    { code: '123456789', description: 'Descrição', type: 'Descrição', id: 7 },
    { code: '123456789', description: 'Descrição', type: 'Descrição', id: 8 },
    { code: '123456789', description: 'Descrição', type: 'Descrição', id: 9 },
    { code: '123456789', description: 'Descrição', type: 'Descrição', id: 10 },
    { code: '123456789', description: 'Descrição', type: 'Descrição', id: 11 },
  ];

  const handleNavigate = () => {
    navigate('products/form');
  }


  const formItems = [
    {
      ref: 'codigo',
      type: 'text',
      placeholder: 'Código',
      onBlur: (value) => { console.log('Código blurred:', value); },
      full: true,
    },
    {
      ref: 'descricao',
      type: 'text',
      placeholder: 'Descrição',
      onBlur: (value) => { console.log('Descrição blurred:', value); },
      full: true,
    },
    {
      ref: 'tipo',
      type: 'select',
      placeholder: 'Tipo',
      options: typeOptions,
      onBlur: (value) => { console.log('Tipo blurred:', value); },
      full: true,
    }
  ];
  return (
    <ContainerAuthenticated>
      <Header title='Produtos' handleNavigate={handleNavigate} />
      <Content>
        <SearchComponent totalResults={rows.length} onFilterClick={() => { setIsActive(!isActive) }} />
        <BasicTable columns={columns} rows={rows} selectable={{ selected, setSelected }} />
      </Content>
      <FilterForms formItems={formItems} isActive={isActive} setIsActive={setIsActive} />

    </ContainerAuthenticated>
  );
}