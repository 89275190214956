export const optionsStates = [
    { id: 1, title: 'AC', name: 'Acre' },
    { id: 2, title: 'AL', name: 'Alagoas' },
    { id: 3, title: 'AP', name: 'Amapa' },
    { id: 4, title: 'AM', name: 'Amazonas' },

    { id: 5, title: 'BA', name: 'Bahia' },
    { id: 6, title: 'CE', name: 'Ceara' },
    { id: 7, title: 'DF', name: 'Distrito Federal' },
    { id: 8, title: 'ES', name: 'Espirito Santo' },
    { id: 9, title: 'GO', name: 'Goias' },
    { id: 10, title: 'MA', name: 'Maranhão' },

    { id: 11, title: 'MT', name: 'Mato Grosso' },
    { id: 12, title: 'MS', name: 'Mato Grosso do Sul' },
    { id: 13, title: 'MG', name: 'Minas Gerias' },
    { id: 14, title: 'PA', name: 'Pará' },
    { id: 15, title: 'PB', name: 'Paraíba' },

    { id: 16, title: 'PR', name: 'Paraná' },
    { id: 17, title: 'PE', name: 'Pernambuco' },
    { id: 18, title: 'PI', name: 'Piauí' },
    { id: 19, title: 'RJ', name: 'Rio de Janeiro' },

    { id: 20, title: 'RN', name: 'Rio Grande do Norte' },
    { id: 21, title: 'RS', name: 'Rio Grande do Sul' },
    { id: 22, title: 'RO', name: 'Rondônia' },
    { id: 23, title: 'RR', name: 'Roraima' },

    { id: 24, title: 'SC', name: 'Santa Catarina' },
    { id: 25, title: 'SP', name: 'São Paulo' },
    { id: 26, title: 'SE', name: 'Sergipe' },
    { id: 27, title: 'TO', name: 'Tocântins' },
]
const sideOptiosCadatro = [
    { ref: 'characteristics', title: 'Características ', page: 'characteristics' },
    { ref: 'events', title: 'Eventos ', page: 'events' },
    { ref: 'processes', title: 'Processos ', page: 'processes' },
    { ref: 'machine-groups', title: 'Grupos de máquinas ', page: 'machine-groups' },
    { ref: 'machine', title: 'Máquinas ', page: 'machine' },
    { ref: 'products', title: 'Produtos ', page: 'products' },
    { ref: 'products-structures', title: 'Estruturas de produtos ', page: 'products-structures' },
    { ref: 'reasons-stopping', title: 'Motivos de parada ', page: 'reasons-stopping' },
    { ref: 'machine-maintenance', title: 'Manutenção de máquinas ', page: 'machine-maintenance' },
];

const sideOptiosMovi = [
    { ref: 'gantt-chart', title: 'Gráfico de gantt ', page: 'gantt-chart' },
    { ref: 'production-order', title: 'Ordem de produção', page: 'production-order' },
    { ref: 'events-launch', title: 'Lançamento de eventos', page: 'events-launch' },
];

export const sideOptions = [
    { ref: 'dashboard', title: "Home", page: 'dashboard' },
    { ref: 'cadastro', title: "Cadastro", sideSubOptios: sideOptiosCadatro },
    { ref: 'movitacoes', title: "Movimentações", sideSubOptios: sideOptiosMovi },
    { ref: 'scenario-parameter', title: "Parâmetros de cenário", page: 'scenario-parameter' },
];

export const eventsOptions = [
    { title: 'tipo1', label: 'Tipo 1', id: 1 },
    { title: 'tipo2', label: 'Tipo 2', id: 2 },
]

export const caracteristicaOptions = [
    { title: 'caracteristica1', label: 'Característica 1', id: 1 },
    { title: 'caracteristica2', label: 'Característica 2', id: 2 },
];

export const dadoOptions = [
    { title: 'dado1', label: 'Dado 1', id: 1 },
    { title: 'dado2', label: 'Dado 2', id: 2 },
];
export const grupoMaquinasOptions = [
    { title: 'grupo1', label: 'Grupo 1', id: 1 },
    { title: 'grupo2', label: 'Grupo 2', id: 2 },
];

export const unidadeFabrilOptions = [
    { title: 'unidade1', label: 'Unidade 1', id: 1 },
    { title: 'unidade2', label: 'Unidade 2', id: 2 },
];
export const statusMaquinaOptions = [
    { title: 'status1', label: 'Status 1', id: 1 },
    { title: 'status2', label: 'Status 2', id: 2 },
];

export const eppOptions = [
    { title: 'epp1', label: 'EPP 1', id: 1 },
    { title: 'epp2', label: 'EPP 2', id: 2 },
];

export const galpaoOptions = [
    { title: 'galpao1', label: 'Galpão 1', id: 1 },
    { title: 'galpao2', label: 'Galpão 2', id: 2 },
];
export const tipoProdutoOptions = [
    { title: 'tipo1', label: 'Tipo 1', id: 1 },
    { title: 'tipo2', label: 'Tipo 2', id: 2 },
];


export const produtoOptions = [
    { title: 'produto1', label: 'Produto 1', id: 1 },
    { title: 'produto2', label: 'Produto 2', id: 2 },
];

export const processoOptions = [
    { title: 'processo1', label: 'Processo 1', id: 1 },
    { title: 'processo2', label: 'Processo 2', id: 2 },
];
export const maquinaOptions = [
    { title: 'maquina1', label: 'Máquina 1', id: 1 },
    { title: 'maquina2', label: 'Máquina 2', id: 2 },

];

export const motivoParadaOptions = [
    { title: 'motivo1', label: 'Motivo 1', id: 1 },
    { title: 'motivo2', label: 'Motivo 2', id: 2 },

];

export const dataInicioOptions = [
    { title: 'data1', label: '01/01/2023', id: 1 },
    { title: 'data2', label: '01/02/2023', id: 2 },

];

export const dataFimOptions = [
    { title: 'data1', label: '31/12/2023', id: 1 },
    { title: 'data2', label: '30/11/2023', id: 2 },

];

export const qtdeOptions = [
    { title: 'qtde1', label: 'Quantidade 1', id: 1 },
    { title: 'qtde2', label: 'Quantidade 2', id: 2 },

];

export const estruturaProdutoOptions = [
    { title: 'estrutura1', label: 'Estrutura 1', id: 1 },
    { title: 'estrutura2', label: 'Estrutura 2', id: 2 },

];

export const tipoAcondicionamentoOptions = [
    { title: 'tipoAcond1', label: 'Tipo Acond. 1', id: 1 },
    { title: 'tipoAcond2', label: 'Tipo Acond. 2', id: 2 },

];

export const tipoOptions = [
    { title: 'tipo1', label: 'Tipo 1', id: 1 },
    { title: 'tipo2', label: 'Tipo 2', id: 2 },

];

export const statusOptions = [
    { title: 'status1', label: 'Status 1', id: 1 },
    { title: 'status2', label: 'Status 2', id: 2 },

];

export const prioridadeOptions = [
    { title: 'prioridade1', label: 'Prioridade 1', id: 1 },
    { title: 'prioridade2', label: 'Prioridade 2', id: 2 },

];

export const proximaOrdemProducaoOptions = [
    { title: 'proxima1', label: 'Próxima 1', id: 1 },
    { title: 'proxima2', label: 'Próxima 2', id: 2 },

];

export const eventoOptions = [
    { title: 'evento1', label: 'Evento 1', id: 1 },
    { title: 'evento2', label: 'Evento 2', id: 2 },

];

export const opMaquinaOptions = [
    { title: 'opMaquina1', label: 'Op/Máquina 1', id: 1 },
    { title: 'opMaquina2', label: 'Op/Máquina 2', id: 2 },

];

export const dataOptions = [
    { title: 'data1', label: '01/01/2023', id: 1 },
    { title: 'data2', label: '01/02/2023', id: 2 },

];

export const operadorOptions = [
    { title: 'operador1', label: 'Operador 1', id: 1 },
    { title: 'operador2', label: 'Operador 2', id: 2 },

];

export const characteristicsOptions = [
    { title: 'evento1', label: 'Evento 1', id: 1 },
    { title: 'evento2', label: 'Evento 2', id: 2 },
]

export const characteristicsTypeOptions = [
    { title: 'caracteristica1', label: 'Característica 1', id: 1 },
    { title: 'caracteristica2', label: 'Característica 2', id: 2 },
];

export const dataTypeOptions = [
    { title: 'dado1', label: 'Dado 1', id: 1 },
    { title: 'dado2', label: 'Dado 2', id: 2 },
];


export const machineGroupOptionsFilter = [
    { title: 'grupo1', label: 'Grupo 1', id: 1 },
    { title: 'grupo2', label: 'Grupo 2', id: 2 },
];

export const factoryUnitOptions = [
    { title: 'unidade1', label: 'Unidade 1', id: 1 },
    { title: 'unidade2', label: 'Unidade 2', id: 2 },
];
export const machineGroupOptions = [
    { title: 'grupo1', label: 'Grupo 1', id: 1 },
    { title: 'grupo2', label: 'Grupo 2', id: 2 },
];

export const statusOptionsFilter = [
    { title: 'ativo', label: 'Ativo', id: 1 },
    { title: 'inativo', label: 'Inativo', id: 2 },
];

export const operatorOptions = [
    { title: 'operador1', label: 'Operador 1', id: 1 },
    { title: 'operador2', label: 'Operador 2', id: 2 },
];

export const warehouseOptions = [
    { title: 'galpao1', label: 'Galpão 1', id: 1 },
    { title: 'galpao2', label: 'Galpão 2', id: 2 },
];

export const typeOptions = [
    { title: 'tipo1', label: 'Tipo 1', id: 1 },
    { title: 'tipo2', label: 'Tipo 2', id: 2 },
];

export const productOptions = [
    { title: 'produto1', label: 'Produto 1', id: 1 },
    { title: 'produto2', label: 'Produto 2', id: 2 },
];

export const processOptions = [
    { title: 'processo1', label: 'Processo 1', id: 1 },
    { title: 'processo2', label: 'Processo 2', id: 2 },
];

export const rawMaterialOptions = [
    { title: 'materia1', label: 'Matéria Prima 1', id: 1 },
    { title: 'materia2', label: 'Matéria Prima 2', id: 2 },
];
export const machineOptions = [
    { title: 'maquina1', label: 'Máquina 1', id: 1 },
    { title: 'maquina2', label: 'Máquina 2', id: 2 },
];

export const downtimeReasonOptions = [
    { title: 'motivo1', label: 'Motivo 1', id: 1 },
    { title: 'motivo2', label: 'Motivo 2', id: 2 },
];

export const productStructureOptions = [
    { title: 'estrutura1', label: 'Estrutura 1', id: 1 },
    { title: 'estrutura2', label: 'Estrutura 2', id: 2 },
];

export const opStatusOptions = [
    { title: 'status1', label: 'Status 1', id: 1 },
    { title: 'status2', label: 'Status 2', id: 2 },
];

export const opTypeOptions = [
    { title: 'tipo1', label: 'Tipo 1', id: 1 },
    { title: 'tipo2', label: 'Tipo 2', id: 2 },
];



export const eventOptions = [
    { title: 'evento1', label: 'Evento 1', id: 1 },
    { title: 'evento2', label: 'Evento 2', id: 2 },
];



export const simulatedEventOptions = [
    { title: 'eventoSimulado1', label: 'Evento Simulado 1', id: 1 },
    { title: 'eventoSimulado2', label: 'Evento Simulado 2', id: 2 },
];

export const salesOrderOptions = [
    { title: 'pedidoVenda1', label: 'Pedido de Venda 1', id: 1 },
    { title: 'pedidoVenda2', label: 'Pedido de Venda 2', id: 2 },
];

export const clientOptions = [
    { title: 'cliente1', label: 'Cliente 1', id: 1 },
    { title: 'cliente2', label: 'Cliente 2', id: 2 },
];

export const purchaseOrderOptions = [
    { title: 'pedidoCompra1', label: 'Pedido de Compra 1', id: 1 },
    { title: 'pedidoCompra2', label: 'Pedido de Compra 2', id: 2 },
];
export const granttChart = [
    { title: '1x', label: 'vezes 1', id: 1 },
    { title: '2x', label: 'vezes 2', id: 2 },
];

export const granttChartMoeda = [
    { title: 'Real', label: 'moeda 1', id: 1 },
    { title: 'Dola', label: 'moeda 2', id: 2 },
];

export const companyOptions = [
    { title: 'empresa1', label: 'Empresa 1', id: 1 },
    { title: 'empresa2', label: 'Empresa 2', id: 2 },
];

export const scenarioOptions = [
    { title: 'cenario1', label: 'Cenário 1', id: 1 },
    { title: 'cenario2', label: 'Cenário 2', id: 2 },
];

export const newScenarioNameOptions = [
    { title: 'novoCenario1', label: 'Novo Cenário 1', id: 1 },
    { title: 'novoCenario2', label: 'Novo Cenário 2', id: 2 },
];