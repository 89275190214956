import React from "react";
import { HeaderAction, HeaderActionIcon, HeaderContainer, HeaderTitle, Content, FilterButton, ButtonContent } from "./styled";
import Button from "components/Form/Button";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Icon } from "ui/styled";


export default function Header({ title, handleNavigate, icon, cancel, save, onFilterClick, noButton, machineMaintenance, calendarExclusion, calendarFabril }) {
  const history = useHistory();
  const back = () => {
    history.goBack();
  }
  return (
    <>
      <HeaderContainer>
        <Content>
          {
            icon &&
            <HeaderAction onClick={back}>
              <HeaderActionIcon src='/icons/back.svg' alt="arrow-left" />
            </HeaderAction>
          }
          <HeaderTitle>
            {title}
          </HeaderTitle>
        </Content>
        {
          !noButton &&
          <Content>
            {cancel && <Button width='131px' color={'cancel'} onClick={cancel}>Cancelar</Button>
            }
            {!save && !onFilterClick ? <>
              <ButtonContent>
                {machineMaintenance ? <>
                  <Button width='199px' link color={'blue'} onClick={() => calendarExclusion && calendarExclusion()}>Excluir calendário fabril</Button>
                  <Button width='199px' color={'lightGrey'} onClick={() => calendarFabril && calendarFabril()}>Calendário fabril</Button>
                </>
                  : null
                }
                <Button width='200px' color={'blue'} onClick={handleNavigate}>Adicionar novo </Button>
              </ButtonContent>
            </> :
              !onFilterClick ? <Button width='131px' margin={'0 5px'} color={'blue'} onClick={save}>Salvar</Button> : <FilterButton onClick={onFilterClick}> <Icon icon="filter" />Filtro
              </FilterButton>
            }

          </Content>
        }
      </HeaderContainer >
    </>
  );
}