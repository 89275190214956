import React, { useEffect } from "react";
import { useHistory } from 'react-router-dom';

import { Row, Col } from 'reactstrap';
import { ReadObject } from "services/storage";
import { ThemedComponent } from "ui/theme";

import {
    SideBackgroundImageContainer,
    SideBackgroundImage,
    SideBackgroundImageDegree,

    FormContent,
    AppLogo,
    Content,
    Touch,
    LogoImage,
    StyledCol
} from './styled'
import { theme } from "ui/theme-color";


export default function ContainerUnauthenticated({ children, blue, green, logo, centred, start }) {

    const history = useHistory();
    const navigate = to => history.push(`/${to}`);

    const init = () => {
        const authentication = ReadObject('authentication')
        if (authentication?.jwt) {
            completeNext()
        }
    }

    const completeNext = () => {
        navigate('dashboard')
    }

    useEffect(() => {
        init()
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <ThemedComponent>
                <Content >
                    <Row
                    >
                        <Col md={{ size: 6 }}
                            className="nopaddings"
                        >
                            <FormContent start={start} blue={blue}>
                                {children}
                            </FormContent>
                        </Col>
                        <StyledCol md={{ size: 6 }} centred={centred}
                            green={green}
                            className="nopaddings"
                        >
                            {logo ?
                                <LogoImage />
                                : <SideBackgroundImage
                                    imgSize={'cover'}
                                    imgUrlBackground={'/images/student.png'} />}
                        </StyledCol>
                    </Row>
                </Content>
            </ThemedComponent >
        </>
    );
}