import styled from 'styled-components';

export const DashboardMenuContainer = styled.div.attrs({
})`           
    background: ${props => props.theme.palette.white.lightWhite};
    width: 284px ;
    overflow-y: auto;
    margin-right: 16px; 
    @media (max-width: 768px) {
        display: none;
    }
    
`;

export const DashboardMenu = styled.div.attrs({
})`           
    background: ${props => props.theme.palette.colors.white};
    display: flex;
    flex-direction: column;
    border-radius: 16px;
    padding: 16px;
    height: 100%;
`;

export const DashboardMenuHeaderUserText = styled.div.attrs({
})`           
    font-size: 15px;
    font-weight: bold;
    color: ${props => props.theme.palette.colors.grey};
    margin-bottom: 12px; 
`;

export const DashboardMenuOption = styled.div.attrs({
})`           
    flex:1;
    border-radius: 4px;
    background: ${props => props.active ? props.theme.palette.colors.green : ''};
`;

export const DashboardMenuContent = styled.div.attrs({
})` 
    flex:1;
    background: ${props => props.lightWhite ? props.theme.palette.white.lightWhite : ''};
    padding: ${props => props.lightWhite ? '10px' : ''};
    border-radius:0 0 4px 4px;
`;

export const DashboardVersionText = styled.div.attrs({
})`
    padding: 12px;
    &:hover{
        cursor: pointer;
    }
`;

export const DashboardLogoContainer = styled.div.attrs({
})`
    display: flex;
    align-items: center;
    justify-content: center; 
    margin-top: 24px;
`;

export const DashboardLogo = styled.img.attrs({
})`
`;

export const DashBoarMenuOptionWrap = styled.div`
    flex: 1;
    margin: 10px 0;
    padding: 12px;
    color: ${props =>
        props.isDashboardOrScenario ? props.theme.palette.colors.black : props.theme.palette.white.main
    };

    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    background: ${props =>
        props.isDashboardOrScenario ? props.theme.palette.colors.white : props.theme.palette.colors.green
    };
    ${props => props.lightWhite ? `
        background: ${props.theme.palette.white.lightWhite};
        color: ${props.theme.palette.colors.green};
        margin: 10px 0 0;
    ` : ''};

    ${props => props.active ? `
        background: ${props.theme.palette.colors.green};
        color: ${props.theme.palette.white.main};
    ` : ''};
    &:hover {
        cursor: pointer;
    }
`;

export const DashboardMenuOptionStatus = styled.div`
    font-size: 12px;
    color: ${props => props.theme.palette.primary.main};  
    font-weight: bold;
    opacity: 0.7;  
    margin-top: 4px;
`;

export const DashboardMenuOptionIcon = styled.img.attrs(props => ({
    src: `/icons/${props.icon}.svg`
}))`
    color: ${props => props.theme.palette.white.main};
`;